import { memo, useMemo, useState } from 'react';
import { Language, ProductDataType, StoreEn } from '../../../../types/types';
import { dictStores } from '../../../../dictionary/dictStores';
import { Flex, ImageComponent, Typography } from '../../../UI';
import SearchProduct from '../../../Navbar/SiteNav/SearchProduct/SearchProduct';
import useSelectedItem, { selectedItemOptionsType } from '../../../../Zustand/stores/useSelectedItem';
import { resolveImgUrl } from '../../../../utils/utils';
import ConfirmSelectedProductReplacement, {
  SuggestedReplacementCardType
} from './ConfirmSelectedProductReplacement/ConfirmSelectedProductReplacement';
import { useCartStore } from '../../../../Zustand';
import SuggestedReplacementCard from './ConfirmSelectedProductReplacement/SuggestedReplacemntCard';

interface IReplaceSelectedBoxProps {
  item: ProductDataType;
  favoriteStore: StoreEn;
  selectedStore: StoreEn;
  language: Language;
  refItemCode: string;
  trigger?: selectedItemOptionsType['trigger'];
}

const ReplaceSelectedBox = ({ item, favoriteStore, selectedStore, language, refItemCode, trigger }: IReplaceSelectedBoxProps) => {
  const [tempSelectedItem, setTempSelectedItem] = useState<null | ProductDataType>(null);
  const { mergeItem } = useSelectedItem();
  const { updateCartItemStoresData } = useCartStore();
  const productName = item[favoriteStore].item_name;

  const updateCards = () => {
    const cardsToRender: SuggestedReplacementCardType[] = [{ type: 'original', productData: item[favoriteStore] }];

    if (trigger === 'cartItem-notFound' && tempSelectedItem) {
      cardsToRender.unshift({ type: 'custom', productData: tempSelectedItem[selectedStore] });
      cardsToRender.push({ type: 'notFoundItem', productData: null });
    } else if (tempSelectedItem) {
      cardsToRender.unshift({ type: 'custom', productData: tempSelectedItem[selectedStore] });
      const type = item[selectedStore].source === 'FE-user-select' ? 'customPrev' : 'auto';
      cardsToRender.push({ type: type, productData: item[selectedStore] });
    }

    return cardsToRender;
  };

  const explanation = useMemo(() => {
    return {
      hebrew: ` ניתן לחפש ישירות ב-${dictStores[selectedStore][language]} ולהחליף ${productName} במוצר דומה לבחירתכם`,
      english: `Search in ${dictStores[selectedStore][language]} and replace ${productName} with other similar product`,
      russian: `Ищите в ${dictStores[selectedStore][language]} и замените ${productName} другим продуктом`,
      arabic: `ابحث في ${dictStores[selectedStore][language]} واستبدل ${productName} بمنتج آخر`
    };
  }, [language, selectedStore, productName]);

  const handleConfirmReplacement = () => {
    if (tempSelectedItem) {
      tempSelectedItem[selectedStore].source = 'FE-user-select';
      updateCartItemStoresData(refItemCode, { ...item, ...tempSelectedItem });
      mergeItem(tempSelectedItem, 'cartItem');
      setTempSelectedItem(null);
    }
  };

  const drawBody = () => {
    if (tempSelectedItem) {
      return (
        <ConfirmSelectedProductReplacement
          language={language}
          onConfirm={handleConfirmReplacement}
          onCancel={() => {
            setTempSelectedItem(null);
          }}
          cards={updateCards()}
        />
      );
    } else if (trigger === 'cartItem-notFound' && !item[selectedStore]) {
      return (
        <Flex justify="space-around" style={{ width: '100%' }}>
          <div style={{ opacity: 0.8 }}>
            <SuggestedReplacementCard type="original" product={item[favoriteStore]} language={language} />
          </div>
          <SuggestedReplacementCard type="notFoundItem" language={language} />
        </Flex>
      );
    }

    return (
      <ImageComponent
        width={150}
        height={150}
        src={resolveImgUrl(selectedStore, item[selectedStore].img_url)}
        alt={item[selectedStore].item_name}
      />
    );
  };

  return (
    <>
      <ReplaceSelectedBoxTitle
        explanationText={explanation[language]}
        store={selectedStore}
        itemName={trigger === 'cartItem-notFound' ? '???' : item[selectedStore].item_name}
        language={language}
        // shouldDrawSearch={true}
        shouldDrawSearch={!item[selectedStore] || item[favoriteStore].item_code !== item[selectedStore].item_code}
        onSelected={selected => {
          setTempSelectedItem(selected);
        }}
      />
      {drawBody()}
    </>
  );
};

export default memo(ReplaceSelectedBox);

interface IReplaceSelectedBoxTitleProps {
  itemName: string;
  store: StoreEn;
  language: Language;
  onSelected: (selected: ProductDataType) => void;
  explanationText: string;
  shouldDrawSearch: boolean;
}
const ReplaceSelectedBoxTitle = ({
  itemName,
  store,
  onSelected,
  language,
  explanationText,
  shouldDrawSearch
}: IReplaceSelectedBoxTitleProps) => {
  return (
    <>
      <Flex flexDirection="column" align="center">
        <Typography.Title align="center" level={3}>
          {itemName}
        </Typography.Title>
        <Typography.Text secondary>{dictStores[store][language]}</Typography.Text>
      </Flex>

      {shouldDrawSearch && (
        <Flex flexDirection="column" style={{ width: '98%' }}>
          <SearchProduct store={store} onSelected={onSelected} language={language} />
          <Typography.Text secondary size={12}>
            *{explanationText}
          </Typography.Text>
        </Flex>
      )}
    </>
  );
};
