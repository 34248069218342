import { create } from 'zustand';
import { ProductDataType, StoreEn } from '../../types/types';

export type selectedItemOptionsType = {
  item: ProductDataType;
  feature: 'main' | 'comparison_cart';
  trigger?: 'productCard' | 'mainSearch' | 'cartItem' | 'cartItem-notFound';
  selectedStoreItem: StoreEn;
};

type SelectedItemStore = {
  item: selectedItemOptionsType['item'] | null;
  trigger?: selectedItemOptionsType['trigger'];
  feature: selectedItemOptionsType['feature'];
  selectedStoreItem?: selectedItemOptionsType['selectedStoreItem'];
  setSelectedProduct: (options: selectedItemOptionsType | null) => void;
  mergeItem: (updatedItem: ProductDataType, trigger?: selectedItemOptionsType['trigger']) => void;
};

const useSelectedItem = create<SelectedItemStore>((set, get) => ({
  item: null,
  feature: 'main',
  setSelectedProduct: options =>
    set(() => ({
      item: options?.item,
      selectedStoreItem: options?.selectedStoreItem,
      trigger: options?.trigger,
      feature: options?.feature
    })),
  mergeItem: (updatedItem, trigger) =>
    set(state => ({
      item: {
        ...state.item,
        ...updatedItem // Merge existing keys with the new ones
      },
      trigger: trigger ?? state.trigger
    }))
}));

export default useSelectedItem;
