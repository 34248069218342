import { FC, Dispatch, SetStateAction } from "react";
import "./FilterCategorySearch.css";
import { CloseBtn, Flex, SelectSearchUI } from "../../../../UI";
import CategoriesMap from "../../../../../assets/Data/categories";
import { Language } from "../../../../../types/types";

type Props = {
  setCategory: Dispatch<SetStateAction<{ key: number; name: string } | null>>;
  category: { key: number; name: string } | null;
  hardCloseFilters?: any;
  language: Language;
};

const FilterCategorySearch: FC<Props> = ({ setCategory, category, hardCloseFilters, language }) => {
  const handleCloseFilters = () => {
    setCategory(null);
    hardCloseFilters(false);
  };

  return (
    <div style={{ padding: "5px", width: "98%", margin: "5px auto", border: "1px solid #dad9d9" }}>
      <Flex justify="space-between">
        <span>בחירת קטגוריה לייעול החיפוש</span>

        <CloseBtn title="סגירת פילטרים" onClick={handleCloseFilters} />
        {/* <span>&times;</span> */}
      </Flex>

      <div style={{ padding: "5px" }}>
        <SelectSearchUI
          dropDownPosition="sticky"
          placeholder="בחרו קטגוריה"
          onChange={(option) =>
            option[0] ? setCategory({ key: parseInt(option[0].value), name: option[0].label }) : null
          }
          values={category ? [{ value: category?.key.toString(), label: category?.name }] : undefined}
          options={CategoriesMap.map((c) => ({ label: c[language], value: c.id.toString() }))}
        />
      </div>
    </div>
  );
};

export default FilterCategorySearch;
