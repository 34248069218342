import { createPortal } from "react-dom";
import { ReactNode, FC, CSSProperties, useEffect, useMemo, useState } from "react";
import "./Modal.css";
import Button, { ButtonProps } from "../Button/Button";
import SpinnerA from "../Loaders/spinnerA/SpinnerA";

import LogoIcon from "../Icons/Logo";
import { Language } from "../../../types/types";
// import { useToken } from "../ThemeProvider/ThemeProvider";
import { resolveDirClass, LangResolvePosition } from "../../../utils/utils";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import CloseBtn from "../CloseBtn/CloseBtn";

// export interface ModalButtons {
//   text: string | ReactNode;
//   onClick: () => void | any;
//   variant?: ButtonProps["variant"];
//   size?: "small" | "medium" | "large";
//   color?: string;
//   backgroundColor?: string;
//   cssClass?: string;
// }

interface ModalProps {
  visible?: boolean;
  title?: string | ReactNode;
  onCancel?: (e: React.MouseEvent<any, MouseEvent>) => void;
  onOk?: (e: React.MouseEvent<any, MouseEvent>) => void;
  okText?: string;
  cancelText?: string;
  loading?: boolean;
  children: ReactNode;
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
  dir?: "ltr" | "rtl";
  buttons?: ButtonProps[];
  appendButtons?: boolean;
  position?: "center" | "top-right" | "bottom-right" | "top-left" | "bottom-left";
  margin?: boolean;
  language?: Language;
  includeCloseBtn?: boolean;
  maskClosable?: boolean;
  zIndex?: number;
  autoTimer?: number;
  cssClass?: string;
  type?: "normal" | "warning" | "logo";
  draggable?: boolean;
}

const Modal: FC<ModalProps> = ({
  title,
  visible,
  onCancel,
  onOk,
  okText,
  cancelText,
  loading,
  children,
  width,
  height,
  buttons,
  style,
  dir,
  appendButtons = false,
  position = "center",
  margin,
  language,
  includeCloseBtn = true,
  maskClosable = true,
  zIndex,
  autoTimer,
  cssClass,
  draggable,
  type = "normal"
}) => {
  const { theme } = useCustomContext();
  const [draggingPosition, setDraggingPosition] = useState<{ x: string | number; y: string | number }>({
    x: `calc(50% - ${width})`,
    y: `calc(50% - ${height})`
  });

  // autoClose
  useEffect(() => {
    let timer: any;
    if (onCancel && autoTimer && visible) {
      timer = setTimeout(() => {
        // @ts-ignore
        onCancel();
      }, autoTimer * 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [visible, onCancel, autoTimer]);

  let modalClassDynamicStyle: CSSProperties = {
    width: width ? width : undefined,
    height: height ? height : undefined,
    direction: dir ? dir : language && resolveDirClass(language),
    backgroundColor: theme.contrast === "theme-dark" ? "var(--bg-primary-color)" : "var(--white)"
  };

  if (style) {
    modalClassDynamicStyle = { ...modalClassDynamicStyle, ...style };
  }

  if (draggable) {
    modalClassDynamicStyle = { ...modalClassDynamicStyle, left: draggingPosition.x, top: draggingPosition.y, position: "fixed" };
  }

  const drawButtons = useMemo(() => {
    const defaultButtons: ButtonProps[] = [
      {
        children: loading ? <SpinnerA size={20} /> : okText || "אישור",
        dataTour: "modal-confirm",
        ariaLabel: "ok",
        onClick: onOk,
        disabled: loading,
        variant: type === "warning" ? "danger" : "primary"
      },
      {
        children: cancelText || "ביטול",
        dataTour: "modal-cancel",
        ariaLabel: "cancel",
        onClick: onCancel,
        disabled: loading,
        type: "default"
      }
    ];

    if (appendButtons && !buttons) throw new Error("Can't use appendButtons without adding `buttons` prop");

    const buttonsToRender = buttons && appendButtons ? [...defaultButtons, ...buttons] : buttons ?? defaultButtons;

    return buttonsToRender.map((btn, idx) => {
      return (
        <Button key={idx} {...btn}>
          {btn.children}
        </Button>
      );
    });
  }, [cancelText, okText, onOk, onCancel, loading, type, buttons, appendButtons]);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    maskClosable && onCancel && onCancel(e);
  };

  const drawModalHeader = () => {
    if (!title) return null;
    let icon: ReactNode | null = null;
    if (type === "logo") icon = <LogoIcon size="24px" />;
    //
    else if (type === "warning") icon = <>⚠️</>;
    const typeClassName = `modal-header-${type}`;

    if (typeof title === "string") {
      return (
        <div className={`modal-header ${typeClassName}`}>
          {icon && <div>{icon}</div>}
          <div>{title}</div>
        </div>
      );
    }
    return <div className={typeClassName}>{title}</div>;
  };
  if (!visible) return null;

  return createPortal(
    <div
      style={{ zIndex }}
      className={`modal-overlay ${resolveDirClass(language || "hebrew")} ${theme.showAnimation} ${theme.showLinksUnderline} ${
        theme.wordSpacing
      } ${theme.contrast} ${theme.fontSize}`}
      onClick={handleOverlayClick}
    >
      <div
        draggable={draggable}
        onDragEnd={e => draggable && setDraggingPosition({ x: e.clientX, y: e.clientY })}
        className={`modal ${margin ? "margin-modal" : ""} ${position.split("-").join(" ")} ${cssClass ?? ""}`}
        style={modalClassDynamicStyle}
        onClick={e => e.stopPropagation()}
      >
        <div>
          {drawModalHeader()}
          {onCancel && includeCloseBtn && (
            <CloseBtn
              position={{ top: 8, [LangResolvePosition(language)]: 8 }}
              onClick={onCancel}
              type="circle-hover"
              tabIndex={0}
              data-tour="modal-close-btn"
            />
          )}
        </div>
        <div className="modal-content">{children}</div>
        <div className="modal-footer">{drawButtons}</div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
