import { CSSProperties, ReactNode } from "react";
import { ResponsiveStyles, useResponsiveStyles } from "../index.hooks";

interface IPropsFlex extends React.HTMLAttributes<HTMLDivElement> {
  justify?: CSSProperties["justifyContent"];
  align?: CSSProperties["alignItems"];
  gap?: number;
  wrap?: CSSProperties["flexWrap"];
  flexDirection?: CSSProperties["flexDirection"];
  style?: CSSProperties;
  children: ReactNode;
  cssClass?: string;
  onClick?: () => void;
  tabIndex?: number;
  responsive?: ResponsiveStyles; // Media query style
  dataTour?: string;
  divRef?: React.RefObject<HTMLDivElement>;
}

const Flex = ({
  tabIndex,
  onClick,
  cssClass,
  justify,
  align,
  gap,
  flexDirection,
  style,
  children,
  responsive,
  dataTour,
  divRef,
  wrap,
  ...rest
}: IPropsFlex) => {
  const dynamicStyle = useResponsiveStyles(responsive);

  return (
    <div
      ref={divRef}
      data-tour={dataTour}
      tabIndex={tabIndex}
      onClick={onClick}
      style={{
        display: "flex",
        flexWrap: wrap,
        justifyContent: justify,
        alignItems: align,
        gap,
        flexDirection,
        ...style,
        ...dynamicStyle
      }}
      className={cssClass}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Flex;
