import { FC } from "react";
import { ProductDataType, StoreEn } from "../../../types/types";
import Promotion from "../Promotions/Promotion";
import AddToCartBtn from "../AddToCartBtn/AddToCartBtn";
import ChangeAmount from "../ChangeAmount/ChangeAmount";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { useCartStore } from "../../../Zustand";
import { ProductCardBodyV2, ProductCardFooterV2 } from "./ProductCardV2";

interface IProductCardGeneric {
  productData: ProductDataType;
  stores: StoreEn[];
  selectedStore: StoreEn;
  item_code: string;
  cssClass?: string;
  lastItemRef?: (node: Element | null) => void;
  onClick: (productData: ProductDataType) => void;
}
const ProductCardGeneric: FC<IProductCardGeneric> = ({
  stores,
  selectedStore,
  item_code,
  productData,
  cssClass,
  lastItemRef,
  onClick,
}) => {
  const {
    user: { language },
  } = useCustomContext();

  const { cart } = useCartStore();
  const { item_name, item_price, unit_qty, img_url } = productData[selectedStore];

  return (
    <>
      <div
        ref={lastItemRef}
        tabIndex={0}
        className={`product_card_wrapper_vvv ${cssClass ? cssClass : ""}`}
        aria-label={productData[stores[0]].item_name}
      >
        <Promotion store={selectedStore} productData={productData} />
        <ProductCardBodyV2 itemName={item_name} imgUrl={img_url} onClick={() => onClick(productData)} />
        <div className="product_card_bottom">
          <ProductCardFooterV2 itemPrice={item_price} unitQty={unit_qty} />
        </div>
        {!cart[item_code] ? (
          <AddToCartBtn language={language} item_code={item_code} productData={productData} />
        ) : (
          <ChangeAmount item_code={item_code} handleInfoClick={() => onClick(productData)} />
        )}
      </div>
    </>
  );
};

export default ProductCardGeneric;
