import { Card, Flex, ImageComponent } from "../../UI";
import SelectedProductInfoTab from "./SelectedProductInfoTab";
import { dictStores } from "../../../dictionary/dictStores";
import { Language, ProductDataType, StoreEn } from "../../../types/types";

interface ISelectedCompareToFavoriteTab {
  store: StoreEn;
  favoriteStore: StoreEn;
  item: ProductDataType;
  language: Language;
}

const SelectedCompareToFavoriteTab = ({ store, favoriteStore, item, language }: ISelectedCompareToFavoriteTab) => {
  return (
    <Flex justify="space-between">
      <Card
        style={{ minWidth: "48%" }}
        subtitle={dictStores[store][language]}
        iconL={<ImageComponent src={item![store].img_url} alt="xx" width={35} />}
      >
        <Flex flexDirection="column" style={{ maxHeight: 200, overflow: "auto" }}>
          <SelectedProductInfoTab direction="column" productData={item![store]} />
        </Flex>
      </Card>
      <Card
        style={{ minWidth: "48%" }}
        subtitle={dictStores[favoriteStore][language]}
        iconL={<ImageComponent src={item![favoriteStore].img_url} alt="xx" width={35} />}
      >
        <Flex flexDirection="column" style={{ maxHeight: 200, overflow: "auto" }}>
          <SelectedProductInfoTab direction="column" productData={item![favoriteStore]} />
        </Flex>
      </Card>
    </Flex>
  );
};

export default SelectedCompareToFavoriteTab;
