import { CSSProperties, ReactNode } from "react";
import { ProductData } from "../../../types/types";
import { addSpacingBetweenNumbersAndLetters } from "../../../utils/utils";
import { Flex, Typography } from "../../UI";

const description = (label: string, value: ReactNode, flexDirection: "column" | "row" = "row") => {
  return (
    <Flex gap={5} flexDirection={flexDirection}>
      <Typography.Text weight={600}>{label}:</Typography.Text>
      <Typography.Text secondary weight={500}>
        {value}
      </Typography.Text>
    </Flex>
  );
};

const SelectedProductInfoTab = ({
  productData,
  direction = "row",
  style
}: {
  direction?: "row" | "column";
  productData?: ProductData;
  style?: CSSProperties;
}) => {
  if (!productData) return null;

  return (
    <Flex
      style={style}
      responsive={{ sm: { flexDirection: "column", gap: 30 } }}
      justify="space-between"
      gap={10}
      flexDirection={direction}
    >
      <Flex flexDirection="column" gap={10}>
        <Typography.Text weight={600} secondary>
          מידע בסיסי
        </Typography.Text>
        {description("קוד פריט", productData.item_code, direction)}

        {description(
          `מחיר ל ${productData.unit_qty}`,
          <Flex>
            <span>{productData.item_price}</span>
            <span>&#8362;</span>
          </Flex>,
          direction
        )}

        {description(
          `מחיר ל ${productData.unit_of_measure}`,
          <Flex>
            <span>{productData.unit_of_measure_price}</span>
            <span>&#8362;</span>
          </Flex>,
          direction
        )}

        {productData.qty_in_package !== -1 && description("כמות בחבילה", productData.qty_in_package, direction)}
        {description("קבוצה", productData.segment, direction)}
        {description("יצרן", `${productData.manufacturer_name} (${productData.manufacture_country})`, direction)}
      </Flex>

      <Flex flexDirection="column" gap={10} style={{ maxWidth: 200 }}>
        <Typography.Text secondary strong>
          מבצעים
        </Typography.Text>
        {productData.promotions ? (
          productData.promotions.map(i => {
            return <Typography.Text key={i.promotion_id}>{addSpacingBetweenNumbersAndLetters(i.promotion_description)}</Typography.Text>;
          })
        ) : (
          <Typography.Text secondary>אין מבצעים</Typography.Text>
        )}
      </Flex>
    </Flex>
  );
};

export default SelectedProductInfoTab;
