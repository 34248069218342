import { FC, memo } from "react";
import { IconCustom } from "./types";

const TriangleIcon: FC<IconCustom> = ({ cssClass, color = "none", size = "16px", clickable }) => {
  return (
    <svg
      className={`${cssClass ?? ""} ${clickable ? "clickable-icon" : ""}`.trim()}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};

export default memo(TriangleIcon);
