import { Dispatch, memo, SetStateAction, useState } from "react";
import { ProductData, ProductDataType, StoreEn } from "../../../../../types/types";
import { Card, Flex, SpinnerA } from "../../../../UI";
import { formFetchDataType } from "../AdminMatchingProducts";

import AdminSelectedModal from "./helpers/AdminSelectedModal";
import AdminStoreItems from "./helpers/AdminStoreItems";

interface IMatchingGridProps {
  stores: { label: string; name: StoreEn; id: number }[];
  formData: formFetchDataType;
  setFormData: Dispatch<SetStateAction<formFetchDataType>>;
  isLoading: boolean;
}

export type AdminSelectedItemType = {
  isOpen: boolean;
  ref: { data: ProductData; name: StoreEn } | null;
  selected: { data: ProductData | null; name: StoreEn } | null;
  adminSuggestion: { data: ProductData; name: StoreEn } | null;
};

const MatchingGrid = ({ stores, formData, isLoading, setFormData }: IMatchingGridProps) => {
  const [selectedItem, setSelectedItem] = useState<AdminSelectedItemType>({
    selected: null,
    ref: null,
    isOpen: false,
    adminSuggestion: null
  });

  const onCloseModal = () => {
    setSelectedItem({ selected: null, ref: null, isOpen: false, adminSuggestion: null });
  };

  const handleSelected = (selected: ProductDataType) => {
    setSelectedItem(prev => {
      return { ...prev, adminSuggestion: { name: prev.selected!.name, data: selected[prev.selected!.name] } };
    });
  };

  const filterSameAsRefStore = () => {
    const { products, filterNameSimilarity, filterSameItemCode } = formData;

    const otherStores = formData.otherStores;

    const data: typeof formData.products = {};

    for (const itemCode in products) {
      const item = formData.products[itemCode];

      for (const storeObj of otherStores) {
        const store = storeObj.data.name as StoreEn;

        const isSameItemCode = item[store]?.item_code === itemCode;
        const isUsingSimilarityMatch = item[store]?.source === "auto-similarity";

        if (filterSameItemCode && isSameItemCode) continue;

        if (filterNameSimilarity && isUsingSimilarityMatch) continue;

        data[itemCode] = item;

        if (item[store]) {
          data[itemCode][store] = item[store];
        }
      }
    }

    return data;
  };

  return (
    <Card cssClass="product_matching_grid_container" radius={"0px"}>
      {isLoading && <SpinnerA absolute size={60} />}
      <Card radius={"0px"} cssClass="scrollable" style={{ padding: 3, width: "100%", height: 500, overflow: "auto" }}>
        <Flex gap={20}>
          {stores.map(s => {
            return (
              <AdminStoreItems
                setSelectedItem={setSelectedItem}
                products={filterSameAsRefStore()}
                key={s.id}
                label={s.label}
                storeNameEn={s.name}
                id={s.id}
                favoriteName={stores[0].name}
              />
            );
          })}
        </Flex>
      </Card>
      <AdminSelectedModal setFormData={setFormData} onCloseModal={onCloseModal} selectedItem={selectedItem} onSelected={handleSelected} />
    </Card>
  );
};

export default memo(MatchingGrid);
