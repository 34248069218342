import { Language, ProductDataType } from "../../../types/types";
import { Button, Flex } from "../../UI";
import { CartIcon } from "../../UI/Icons";
import { dictButtonsAndActions } from "../../../dictionary/dictButtons";
import useCartStore from "../../../Zustand/stores/useCartStore";

type Props = {
  item_code: string;
  productData: ProductDataType;
  language: Language;
};

const AddToCartBtn = ({ item_code, productData, language }: Props) => {
  const { addToCart } = useCartStore();

  const handleAddToCart = () => {
    addToCart(item_code, productData, 1);
  };
  return (
    <Button size="small" variant="secondary" cssClass="add_cart_btn" onClick={handleAddToCart}>
      <Flex align="center" justify="space-around">
        <span>
          <CartIcon size={"1.5rem"} color="white" />
        </span>
        <span>{dictButtonsAndActions.addToCart[language]}</span>
      </Flex>
    </Button>
  );
};

export default AddToCartBtn;
