import { PromotionTable } from "../../types/types";
import { Flex, Tooltip, Typography } from "../UI";
import { PromoIconTag } from "../UI/Icons";

const Promotion = ({ promotion, unit }: { promotion: PromotionTable; unit: string }) => {
  return (
    <Flex
      gap={10}
      align="center"
      style={{ background: "var(--bg-secondary-color)", padding: "2px 4px", borderRadius: 8, width: "fit-content" }}
    >
      <PromoIconTag />

      <Flex gap={5} align="center" style={{ background: "var(--bg-secondary-color)", padding: "2px 4px", borderRadius: 8 }}>
        <Tooltip
          title={
            <>
              {promotion.max_qty ? (
                <Typography.Text size={15}>
                  <span>מוגבל ל </span>
                  <span>{promotion.max_qty} </span>
                  <span>{unit}</span>
                </Typography.Text>
              ) : null}
              {promotion.min_purchase_amount ? (
                <Typography.Text size={15}>
                  <span>בקנייה מעל </span>
                  <span>{promotion.min_purchase_amount} </span>
                  <span>&#8362;</span>
                </Typography.Text>
              ) : null}
              <Typography.Text size={15} secondary={!!promotion.max_qty && !!promotion.min_purchase_amount}>
                <span>בתוקף עד :</span>
                <span>{new Date(promotion.promotion_end_date).toLocaleDateString()}</span>
              </Typography.Text>
            </>
          }
        >
          <Typography.Text size={15} weight={550}>
            {promotion.min_qty > 1 ? (
              <>
                <span>{promotion.min_qty} </span>
                <span>ב- </span>
              </>
            ) : (
              <span>מבצע! </span>
            )}
            <span>&#8362;</span>
            <span>{promotion.discounted_price} </span>
          </Typography.Text>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Promotion;

// carrefour

// discounted by x %
//* calc by: discounted rate => x% = discounted_rate /100
//* view by: promotion_description
// reward_type - not relevant
// discounted_price - null
// min_qty - include
// max_qty - include
