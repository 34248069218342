import { useEffect, useState, useRef } from "react";
import "../Shared/Products.css";
import { useLocation } from "react-router-dom";
import { ProductDataType } from "../../../types/types";
import { SideBar } from "../../../components/UI";
import Cart from "../../../components/Cart/Cart";
import ScrollTopBtn from "../../../components/UI/ScrollTopBtn/ScrollTopBtn";
import fetchProductsV2 from "./fetch_products_v2";
import drawProducts from "../Shared/DrawProducts";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import ProductsFilter from "../../../components/ProductsFilter/ProductsFilter";
import { FilterOptionsState } from "../../../components/ProductsFilter/helpers/types";
import { initFiltersState, productsFilterCB, productsFilterInitialState } from "../../../components/ProductsFilter/helpers/utils";
import DrawProductsSkeleton from "../Shared/DrawProductsSkeleton";
import { useCartStore } from "../../../Zustand";

const ProductsV2 = () => {
  const location = useLocation();
  const {
    onlineStores: { favoriteStore }
  } = useCustomContext();
  const { isCartOpen } = useCartStore();
  const [products, setProducts] = useState<ProductDataType[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [filterOptions, setFilterOptions] = useState(productsFilterInitialState);
  const subcategory = parseInt(encodeURIComponent(`${location.pathname}`.replace("/products/", "")));

  useEffect(() => {
    const fetchAndInit = async () => {
      const data = await fetchProductsV2({ subcategory, favoriteStore, setIsLoading });

      const fillFilterOptions: FilterOptionsState = initFiltersState(data, favoriteStore);
      setFilterOptions(fillFilterOptions);
      setProducts(data);
    };
    fetchAndInit();
  }, [subcategory, favoriteStore]);

  return (
    <>
      <div style={{ display: "flex" }}>
        {products.length > 0 && <ProductsFilter key={subcategory} setFilterOptions={setFilterOptions} filterOptions={filterOptions} />}
        <div ref={ref} className={`product-main-container ${isCartOpen ? "responsive-hidden" : ""}`}>
          {isLoading ? (
            <DrawProductsSkeleton numOfElements={15} />
          ) : (
            drawProducts(
              favoriteStore,
              products.filter(p => productsFilterCB(favoriteStore, p, filterOptions))
            )
          )}
        </div>

        <ScrollTopBtn targetElementRef={ref} />
        <SideBar isOpen={isCartOpen}>
          <Cart />
        </SideBar>
      </div>
      {/* <SelectedProduct store={favoriteStore} /> */}
    </>
  );
};

export default ProductsV2;
