import { FC } from "react";
import "./ProductCard.css";
import { ProductDataType } from "../../types/types";

import ProductCardGeneric from "./ProductCardLayoutVersions/ProductCardGeneric";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import { resolveImgUrl } from "../../utils/utils";
import useSelectedItem from "../../Zustand/stores/useSelectedItem";

type Props = {
  productData: ProductDataType;
  item_code: string;
  cssClass?: string;
  lastItemRef?: (node: Element | null) => void; // accept callback ref here
};

const ProductCard: FC<Props> = ({ productData, item_code, cssClass, lastItemRef }) => {
  const {
    onlineStores: { favoriteStore },
  } = useCustomContext();
  const { setSelectedProduct } = useSelectedItem();
  const stores = [favoriteStore];
  productData[favoriteStore].img_url = resolveImgUrl(favoriteStore, productData[favoriteStore].img_url);

  const handleOnClick = () => {
    setSelectedProduct({
      item: productData,
      selectedStoreItem: favoriteStore,
      feature: "main",
      trigger: "productCard",
    });
  };

  return (
    <ProductCardGeneric
      lastItemRef={lastItemRef}
      cssClass={cssClass}
      productData={productData}
      selectedStore={favoriteStore}
      item_code={item_code}
      stores={stores}
      onClick={handleOnClick}
    />
  );
};

export default ProductCard;
