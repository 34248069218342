import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import "./SelectSearchUI.css";
import { Language } from "../../../../types/types";
import { CheckVXICon, TriangleIcon } from "../../Icons";

export type SelectSearchOptionType = {
  value: string;
  label: string;
  data?: any;
};

type SelectProps = {
  placeholder?: string;
  options: SelectSearchOptionType[];
  onChange?: (selectedOptions: SelectSearchOptionType[]) => void;
  label?: { text: string; style?: CSSProperties };
  language?: Language;
  showSelectedTags?: boolean;
  values?: SelectSearchOptionType[];
  isMulti?: boolean;
  icon?: ReactNode;
  dropDownPosition?: "absolute" | "sticky";
  keepValuesOnRemove?: string[];
  required?: boolean;
  searchable?: boolean;
  name?: string;
  disabled?: boolean;
  styleInput?: CSSProperties;
  style?: CSSProperties;
};

const SelectSearchUI = ({
  options,
  onChange,
  placeholder,
  label = { text: "" },
  language = "hebrew",
  showSelectedTags = false,
  values,
  icon,
  isMulti = false,
  dropDownPosition = "absolute",
  keepValuesOnRemove,
  required,
  searchable,
  styleInput,
  name,
  style,
  disabled
}: SelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectSearchOptionType[]>(values ? values : []);
  const [searchText, setSearchText] = useState(!isMulti && values?.length ? values[0].label : "");
  const [openDropDown, setOpenDropDown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: SelectSearchOptionType) => {
    if (searchable && !isMulti) {
      setSearchText(p => (p === option?.label ? "" : option.label));
    }

    const optionIndex = selectedOptions.findIndex(selectedOption => selectedOption.value === option.value);

    const shouldAdd = optionIndex === -1;

    if (shouldAdd) {
      const dataToAdd = isMulti ? [...selectedOptions, option] : [option];
      onChange && onChange(dataToAdd);
      setSelectedOptions(dataToAdd);
    } else {
      const filteredOptions = selectedOptions.filter(options => options.value !== option.value);
      onChange && onChange(filteredOptions);
      setSelectedOptions(filteredOptions);
    }
  };

  const handleRemoveOption = (option: SelectSearchOptionType) => {
    const updatedSelectedOptions = selectedOptions.filter(selectedOption => selectedOption.value !== option.value);
    setSelectedOptions(updatedSelectedOptions);
    onChange && onChange(updatedSelectedOptions);
  };

  const handleRemoveAll = () => {
    if (keepValuesOnRemove) {
      setSelectedOptions(prev => {
        return prev.filter(o => keepValuesOnRemove.includes(o.value));
      });

      const updatedSelectedOptions = selectedOptions.filter(selectedOption => keepValuesOnRemove.includes(selectedOption.value));
      onChange && onChange(updatedSelectedOptions);
    } else {
      setSelectedOptions([]);
      onChange && onChange([]);
    }

    if (!isMulti) setSearchText("");
  };

  const filteredOptions = options.filter(option => option.label.toLowerCase().includes(searchText.toLowerCase()));

  useEffect(() => {
    if (values?.length && values[0].value) {
      setSelectedOptions(values);
    }
  }, [values]);

  const handleOnFocus = () => {
    !disabled && setOpenDropDown(true);
  };

  const handleOnBlur = (e: any) => {
    if (ref.current && !ref.current.contains(e.relatedTarget as Node)) {
      setOpenDropDown(false);
    }
  };

  const drawIcon = () => {
    if (icon) {
      return <span className={`${!["hebrew", "arabic"].includes(language) ? "rtl-icon" : "ltr-icon"}`}>{icon}</span>;
    }

    if (selectedOptions.length > 0 && (!keepValuesOnRemove || selectedOptions.length > keepValuesOnRemove.length)) {
      return (
        <div onClick={handleRemoveAll} className="ssui-icon ssui-remove-all">
          &#10005;
        </div>
      );
    }

    return (
      <div className="ssui-icon">
        <TriangleIcon />
      </div>
    );
  };

  return (
    <div ref={ref} className={`dynamic-select`} onBlur={handleOnBlur} onFocus={handleOnFocus} tabIndex={0} style={style}>
      {label && (
        <label className={`pos-${language} dir-${language} ssui-label-top`} style={label.style} htmlFor={name}>
          {label.text}
        </label>
      )}
      <div className={`selected-options ${openDropDown ? "selected-options-open" : ""}  ${disabled ? "search-input-disabled" : ""}`}>
        {showSelectedTags &&
          selectedOptions.map(option => (
            <div key={option.value} className="selected-option-tag scrollable">
              {(!keepValuesOnRemove || !keepValuesOnRemove.includes(option.value)) && (
                <div className="remove-option" onClick={() => handleRemoveOption(option)}>
                  &#10005;
                </div>
              )}
              <h6>{option.label}</h6>
            </div>
          ))}

        <input
          readOnly={!searchable}
          type="text"
          style={styleInput}
          className={`search-input`}
          placeholder={selectedOptions.length ? selectedOptions.map(s => s.label).join(" , ") : placeholder}
          value={searchText}
          required={required}
          onChange={e => searchable && setSearchText(e.target.value)}
          disabled={disabled}
        />

        {drawIcon()}
      </div>
      {openDropDown && (
        <div className="option-list scrollable" style={{ position: dropDownPosition }}>
          {filteredOptions.map(option => {
            const isChecked = selectedOptions.find(o => o.value === option.value);

            return (
              <div key={option.value} className={`option ${isChecked ? "selected" : ""}`} onClick={() => handleOptionClick(option)}>
                {option.label}
                {isChecked && (
                  <span className="checkmark">
                    <CheckVXICon isCircle={false} color="green" shape="v" />
                  </span>
                )}
              </div>
            );
          })}
          {filteredOptions.length === 0 && <div className="no-results">לא נמצא תוצאות</div>}
        </div>
      )}

      {/* Hidden input field for FormData */}
      <input type="hidden" name={name} value={JSON.stringify(selectedOptions)} />
    </div>
  );
};

export default SelectSearchUI;
