import { CSSProperties, ReactNode } from "react";
import "./InfoBox.css";
import { Language } from "../../../types/types";

export type InfoBoxType = "info" | "gray" | "warning" | "success" | "error";

interface IinfoBoxProps {
  content: string | ReactNode;
  type: InfoBoxType;
  language: Language;
  fontColor?: string;
  cssClass?: string;
  bgColor?: string;
  style?: CSSProperties;
  border?: boolean;
}

const InfoBox = ({
  content,
  type = "info",
  language = "hebrew",
  fontColor,
  cssClass,
  bgColor,
  style,
  border = true,
}: IinfoBoxProps) => {
  return (
    <div
      className={`info-box info-box-${type} info-box-${language} ${!border ? "info-box-no-border" : ""} ${
        cssClass ?? ""
      }`}
      style={{ color: fontColor, backgroundColor: bgColor, ...style }}
    >
      <p>{content}</p>
    </div>
  );
};

export default InfoBox;
