import { useState, ReactNode, FC, CSSProperties } from "react";
import "./Tabs.css";
import Flex from "../Flex/Flex";
import { ResponsiveStyles } from "../index.hooks";

export interface Tab extends Record<string, any> {
  label: string;
  key: string;
  content: ReactNode;
  disabled?: boolean;
  tooltipContent?: string;
}

interface TabsProps {
  tabs: Tab[];
  defaultActiveKey?: string;
  vertical?: boolean;
  contentStyle?: CSSProperties;
  contentCss?: string;
  responsive?: ResponsiveStyles; // Media query style
  cssClass?: string;
}

const Tabs: FC<TabsProps> = ({ tabs, defaultActiveKey, vertical = false, contentStyle, responsive, contentCss, cssClass }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveKey || (tabs.length ? tabs[0].key : ""));
  console.log(tabs);
  if (!tabs.length) return null;

  return (
    <Flex
      flexDirection={vertical ? "row" : "column"}
      gap={vertical ? 30 : 0}
      className={`ui-tabs-wrapper ${cssClass ?? ""}`}
      responsive={responsive}
    >
      <Flex flexDirection={vertical ? "column" : "row"} cssClass={vertical ? "ui-tabs-border-vertical" : "ui-tabs-border-horizontal"}>
        {tabs.map(tab => (
          <div
            key={tab.key}
            className={`ui-tab ${activeTab === tab.key ? "ui-tab-active" : ""} ${tab.disabled ? "ui-tab-disabled" : ""}`}
            onClick={() => !tab.disabled && setActiveTab(tab.key)}
          >
            {tab.tooltipContent && <div className="ui-tab-disabled-message">{tab.tooltipContent}</div>}
            {tab.label}
          </div>
        ))}
      </Flex>
      <div className={contentCss ?? ""} style={contentStyle}>
        {tabs.find(tab => tab.key === activeTab)?.content}
      </div>
    </Flex>
  );
};

export default Tabs;
