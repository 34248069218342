import { useEffect, useMemo, useState } from 'react';import { Language, ProductData } from '../../../../../types/types';
import { CheckVXICon } from '../../../../UI/Icons';
import { Card, Flex, ImageComponent, Typography } from '../../../../UI';
import { SuggestedReplacementCardType } from './ConfirmSelectedProductReplacement';

interface ISuggestedReplacementCardProps {
  type: SuggestedReplacementCardType['type'];
  language: Language;
  product?: ProductData | null;
  color?: string;
}

const SuggestedReplacementCard = ({ type, language, product, color }: ISuggestedReplacementCardProps) => {
  const [productState, setProductState] = useState({ ...product });

  useEffect(() => {
    if (!product) {
      setProductState({ item_name: '???', item_price: 0, img_url: 'xxx' });
    }
  }, [product]);

  const dict = {
    original: {
      hebrew: 'מקור (חנות ראשית)',
      english: 'Source (Main Store)',
      russian: 'Источник (Основной магазин)',
      arabic: 'المصدر (المتجر الرئيسي)'
    },
    notFoundItem: {
      hebrew: 'פריט חסר',
      english: 'Missing Item',
      russian: 'xxx',
      arabic: 'xxx'
    },
    custom: {
      hebrew: 'המוצר שהתאמת',
      english: 'The product you matched',
      russian: 'Продукт, который вы сопоставили',
      arabic: 'المنتج الذي قمت بمطابقته'
    },
    customPrev: {
      hebrew: 'ההתאמה קודמת שלך',
      english: 'Your previous match',
      arabic: 'تطابقك السابق',
      russian: 'Ваше предыдущее совпадение'
    },
    auto: {
      hebrew: 'התאמה אוטומטית',
      english: 'Automatic Matching',
      russian: 'Автоматическое соответствие',
      arabic: 'مطابقة تلقائية'
    }
  };

  const obj: {
    borderColor: string;
    title: string;
  } = {
    borderColor: color ?? 'transparent',
    title: dict[type][language]
  };

  const Icon = useMemo(() => {
    let shape: 'x' | 'v' = 'x';
    switch (type) {
      case 'auto':
      case 'notFoundItem':
      case 'customPrev':
        shape = 'x';
        break;
      case 'custom':
        shape = 'v';
        break;
      default:
        return null;
    }
    return <CheckVXICon outlined shape={shape} size="14" color={color} />;
  }, [type, color]);

  return (
    <Card style={{ border: `1px solid ${obj.borderColor}`, background: 'var(--bg-weak-color)', width: 150 }} iconR={Icon}>
      <Flex flexDirection="column" gap={1} align="center">
        <Flex align="center" gap={2}>
          <Typography.Text align="center" secondary size={12}>
            {obj.title}
          </Typography.Text>
        </Flex>
        {product ? (
          <ImageComponent src={productState.img_url} alt={productState.item_name ?? ''} width={50} height={50} />
        ) : (
          <div className="cart-item_not_found_in_cart_img cart-item_not_found_in_cart_img-large"></div>
        )}
        <Typography.Text size={14} weight={600} align="center" width={'100%'} ellipsis>
          {productState.item_name}
        </Typography.Text>

        <Typography.Text size={12}>
          <span>&#8362;</span>
          <span>{productState.item_price}</span>
        </Typography.Text>
      </Flex>
    </Card>
  );
};

export default SuggestedReplacementCard;
