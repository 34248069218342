import { FC, useState, useEffect, useRef, ReactNode, CSSProperties } from "react";
import { createPortal } from "react-dom";
import "./AlertMessage.css";
import { CheckVXICon, InfoIcon } from "../Icons";
import WarningIcon from "../Icons/MessagesIcons/WarningIcon";
import { InfoBoxType } from "../InfoBox/InfoBox";
import Flex from "../Flex/Flex";
import { resolveDirClass } from "../../../utils/utils";
import CloseBtn from "../CloseBtn/CloseBtn";
import { Language } from "../../../types/types";

type Props = {
  message: ReactNode | string | undefined | null;
  delay?: number | null;
  type?: InfoBoxType;
  position?: "center" | "top" | "bottom" | "top-right" | "bottom-right" | "top-left" | "bottom-left";
  cssClass?: string;
  onMessageEnd?: () => void;
  showIcon?: boolean;
  style?: CSSProperties;
  title?: string | ReactNode;
  language?: Language;
};

const AlertMessage: FC<Props> = ({
  message,
  title,
  style,
  position = "top-right",
  delay,
  type,
  cssClass,
  onMessageEnd,
  showIcon = true,
  language,
}) => {
  const [isOpen, setIsOpen] = useState(!!message);
  let timerID = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (message) {
      setIsOpen(true);
      if (delay) {
        timerID.current = setTimeout(() => {
          setIsOpen(false);
        }, delay * 1000);
      }
    } else {
      setIsOpen(false);
    }

    return () => {
      if (timerID.current) {
        clearTimeout(timerID.current);
      }
    };
  }, [message, delay]);

  useEffect(() => {
    !isOpen && onMessageEnd && onMessageEnd();
  }, [isOpen, onMessageEnd]);

  if (!isOpen) return null;

  const handleClick = () => {
    if (timerID.current) {
      clearTimeout(timerID.current);
    }
    setIsOpen(false);
  };

  const icon: { [key in InfoBoxType]: ReactNode } = {
    warning: <WarningIcon onClick={handleClick} size="1.2em" clickable />,
    error: <CheckVXICon shape="x" outlined onClick={handleClick} size="1.2em" clickable />,
    success: <CheckVXICon shape="v" outlined onClick={handleClick} size="1.2em" clickable />,
    info: <InfoIcon size="1.2em" onClick={handleClick} clickable />,
    gray: <div></div>,
  };

  const drawNotification = () => {
    return (
      <Flex flexDirection="column" gap={15} cssClass="message-container" style={style}>
        <Flex justify="space-between" gap={30}>
          <Flex gap={3} align="center">
            {showIcon && <div>{icon[type || "info"]}</div>}
            <b>{title}</b>
          </Flex>
          <div>
            <CloseBtn onClick={() => setIsOpen(false)} />
          </div>
        </Flex>
        <Flex>
          <div>{message}</div>
        </Flex>
      </Flex>
    );
  };

  return createPortal(
    <div tabIndex={0} className={`${resolveDirClass(language || "hebrew")} warning-modal ${position} ${cssClass}`}>
      {drawNotification()}
    </div>,
    document.body
  );
};

export default AlertMessage;
