import { Dispatch, SetStateAction } from "react";
import { ProductData, StoreEn } from "../../../../../../types/types";
import { AdminSelectedItemType } from "../MatchingGrid";
import { Card, Flex, ImageComponent, Typography } from "../../../../../UI";
import { drawMatchScoringIcon, findCategoryObject, findSubCategoryObject, resolveImgUrl } from "../../../../../../utils/utils";

const AdminItemCard = ({
  store,
  favoriteName,
  product,
  refProduct,
  setSelectedItem
}: {
  store: StoreEn;
  favoriteName: StoreEn;
  product: ProductData | null;
  refProduct: ProductData;
  setSelectedItem?: Dispatch<SetStateAction<AdminSelectedItemType>>;
}) => {
  const handleSelected = () => {
    setSelectedItem &&
      setSelectedItem({
        isOpen: true,
        selected: { data: product, name: store },
        ref: { data: refProduct, name: favoriteName },
        adminSuggestion: null
      });
  };
  return (
    <Card
      onClick={handleSelected}
      style={{ padding: 0 }}
      hover={!!setSelectedItem}
      contentStyle={{ padding: "2px 8px", background: product ? undefined : "rgba(250, 155, 155, 0.2)" }}
    >
      <Flex gap={5} justify="space-between">
        <div style={{ minWidth: 80 }}>
          <ImageComponent src={resolveImgUrl(store, product?.img_url)} alt="item" width={75} height={75} />
        </div>

        <Flex flexDirection="column" justify="space-around">
          <Typography.Text size={14} strong ellipsis width={180}>
            {product?.item_name ?? "???"}
          </Typography.Text>
          <Flex flexDirection="column">
            <Typography.Text size={11} secondary>
              {product?.item_code ?? "???"}
            </Typography.Text>
            <Typography.Text size={11} secondary ellipsis width={150}>
              {findCategoryObject(product?.category ?? -1).hebrew}/
              {findSubCategoryObject(product?.category ?? -1, product?.sub_category ?? -1)?.hebrew}
            </Typography.Text>
          </Flex>
          {/* <Typography.Text size={14} strong>
              {product?.item_price ?? "???"}שח
            </Typography.Text> */}
        </Flex>

        <Flex justify="space-around" align="flex-end" flexDirection="column" style={{ flex: 0 }}>
          <Typography.Text size={14} color="#1e40af" style={{ background: "#dbeafe", borderRadius: "5px", padding: "3px 5px" }} secondary>
            {drawMatchScoringIcon(product?.source)}
          </Typography.Text>
          <Typography.Text size={11} secondary>
            {product?.item_price ?? "???"}שח
          </Typography.Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default AdminItemCard;
