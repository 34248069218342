import { Language } from "../../../types/types";

export type AuthenticationFormState = {
  email: string;
  password: string;
  confirmPassword: string;
  full_name: string;
  phone_number?: string;
  city?: string;
  language: Language;
};

export type ActionAuth =
  | { type: "SET_EMAIL"; payload: string }
  | { type: "SET_PASSWORD"; payload: string }
  | { type: "SET_CONFIRM_PASSWORD"; payload: string }
  | { type: "SET_FULL_NAME"; payload: string }
  | { type: "SET_PHONE"; payload: string }
  | { type: "SET_CITY"; payload: string }
  | { type: "SET_LANGUAGE"; payload: Language };

export const AuthenticationInitialState: AuthenticationFormState = {
  email: "",
  password: "",
  confirmPassword: "",
  full_name: "",
  phone_number: "",
  city: "",
  language: "hebrew"
};

export const AuthenticationFormReducer = (state: AuthenticationFormState, action: ActionAuth) => {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_PASSWORD":
      return { ...state, password: action.payload };
    case "SET_CONFIRM_PASSWORD":
      return { ...state, confirmPassword: action.payload };
    case "SET_FULL_NAME":
      return { ...state, full_name: action.payload };
    case "SET_PHONE":
      return { ...state, phone_number: action.payload };
    case "SET_CITY":
      return { ...state, city: action.payload };
    case "SET_LANGUAGE":
      return { ...state, language: action.payload };
    default:
      return state;
  }
};
