export const dictButtonsAndActions = {
  addToCart: {
    hebrew: "הוסף לעגלה",
    english: "Add to cart",
    russian: "Добавить в корзину",
    arabic: "أضف إلى السلة",
  },
  removeFromCart: {
    hebrew: "הסר מהעגלה",
    english: "Remove from cart",
    russian: "Убрать из корзины",
    arabic: "إزالة من السلة",
  },
  ok: {
    hebrew: "אישור",
    english: "OK",
    russian: "ОК",
    arabic: "موافق",
  },
  cancel: {
    hebrew: "ביטול",
    english: "Cancel",
    russian: "Отмена",
    arabic: "إلغاء",
  },
  continueShopping: {
    hebrew: "המשך קנייה",
    english: "Continue Shopping",
    russian: "Продолжить покупки",
    arabic: "متابعة التسوق",
  },
  close: {
    hebrew: "סגור",
    english: "Close",
    russian: "Закрыть",
    arabic: "إغلاق",
  },
};
