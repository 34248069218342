import { parseCookies } from "../../utils/utils";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { User, OnlineStoresType, CookieName } from "../../types/types";
import useLocalStorage, { localStorageKeys } from ".././useLocalStorage";
import { getUserInitialValue, onlineStoresInitialValue, ThemeInitialValue } from "./helpers/initialValues";
import { ThemeType } from "../../components/Accessability/AccessabilityContent/AccesabilityContentOptions/AccessabilityOption";

export interface SiteContextType {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  children?: JSX.Element;
  theme: ThemeType;
  setTheme: Dispatch<SetStateAction<ThemeType>>;
  setIsShoppingListOpen: Dispatch<SetStateAction<boolean>>;
  isShoppingListOpen: boolean;
  onlineStores: OnlineStoresType;
  setOnlineStores: Dispatch<SetStateAction<OnlineStoresType>>;
}

export const SiteContext = createContext<SiteContextType>({} as SiteContextType);

export const SiteProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>(getUserInitialValue(parseCookies(CookieName.AuthUserData)));
  const [isShoppingListOpen, setIsShoppingListOpen] = useState(false);

  const [theme, setTheme] = useLocalStorage(localStorageKeys.AccessabilityOptions, ThemeInitialValue);
  const [onlineStores, setOnlineStores] = useLocalStorage(localStorageKeys.userOnlineStores, onlineStoresInitialValue);

  return (
    <SiteContext.Provider
      value={{
        user,
        setUser,
        theme,
        setTheme,
        setIsShoppingListOpen,
        isShoppingListOpen,
        onlineStores,
        setOnlineStores,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

const useCustomContext = () => useContext(SiteContext);
export default useCustomContext;
