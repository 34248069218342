import { Button, Flex, InfoBox } from '../../../../UI';
import SuggestedReplacementCard from './SuggestedReplacemntCard';
import { Language, ProductData } from '../../../../../types/types';

type SuggestedReplacementType = 'original' | 'auto' | 'custom' | 'customPrev' | 'notFoundItem';

export type SuggestedReplacementCardType = { type: SuggestedReplacementType; productData: ProductData | null };

interface IConfirmSelectedProductReplacementProps {
  language: Language;
  onConfirm: () => void;
  onCancel: () => void;
  cards: SuggestedReplacementCardType[];
}

const ConfirmSelectedProductReplacement = ({ language, onCancel, onConfirm, cards }: IConfirmSelectedProductReplacementProps) => {
  const okColor = '#a6f7c2';
  const cancelColor = '#fca9a9';

  const text = 'נא לאשר את החלפת ההתאמה האוטומטית במוצר שבחרת או בטלו כדי להישאר עם ההתאמה האוטומטית';

  const drawCards = () => {
    return cards.map((c, i) => {
      const color = c.type === 'custom' ? okColor : c.type === 'original' ? undefined : cancelColor;
      return <SuggestedReplacementCard key={i} type={c.type} product={c.productData} language={language} color={color} />;
    });
  };

  return (
    <Flex flexDirection="column" gap={5}>
      <InfoBox type="info" content={text} language={language} border={false} />
      <Flex justify="space-between" gap={5} responsive={{ sm: { flexWrap: 'wrap' } }}>
        {drawCards()}
      </Flex>
      <Flex gap={5} style={{ width: '50%', marginTop: 10 }}>
        <Button size="small" backgroundColor={okColor} color="var(--primary-color)" onClick={onConfirm}>
          אשר החלפה
        </Button>
        <Button size="small" backgroundColor={cancelColor} color="var(--primary-color)" onClick={onCancel}>
          ביטול
        </Button>
      </Flex>
    </Flex>
  );
};

export default ConfirmSelectedProductReplacement;
