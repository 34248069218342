import serverAPI from "../../api/server";
import { goToErrorPage } from "../../components/ErrorPage/helpers/errorPageHelper";
import { initialSUserState } from "../../hooks/useCustomContext/helpers/initialValues";
import { localStorageKeys } from "../../hooks/useLocalStorage";
import { User } from "../../types/types";
import { checkTokenExpIsValid, parseJwt } from "../../utils/utils";

class CsrfToken {
  private _csrfToken: string;

  constructor() {
    this._csrfToken = window.sessionStorage.getItem(localStorageKeys.CsrfSession) || "";
  }

  private async fetchToken(email: string, isUser: boolean): Promise<{ cs: string | null; redirect: string | null }> {
    const res = await serverAPI("client/init", {
      email: email === "guest" ? "null" : email,
      isUser: isUser
    });
    return res.data;
  }

  public validateToken() {
    const decoded = parseJwt<Partial<User>>(this._csrfToken);
    const isValid = checkTokenExpIsValid(decoded);

    if (!this._csrfToken || !decoded || !isValid) {
      // alert(`Error Detected Please Refresh The Page`);
      return false;
    }
    return true;
  }

  public setToken(token: string) {
    this._csrfToken = token;
    window.sessionStorage.setItem(localStorageKeys.CsrfSession, token);
    return this;
  }

  public async setTokenAsync(email: string, isUser: boolean): Promise<CsrfToken> {
    const { cs, redirect } = await this.fetchToken(email, isUser);
    if (redirect) {
      window.location.href = redirect;
      cs && this.setToken(cs as string);
    } else {
      this.setToken(cs as string);
    }
    return this;
  }

  public getValidatedToken(): string {
    if (this.validateToken()) return this._csrfToken;
    return "";
  }

  public async initOrUpdateCsrfToken(authToken?: string) {
    const decodedUser = authToken ? parseJwt<User>(authToken) : initialSUserState;
    const isUser = decodedUser?.isUser || initialSUserState.isUser;
    const email = decodedUser?.email || initialSUserState.email;

    await this.setTokenAsync(email, isUser);
    const csrfTokenToReturn = this.getValidatedToken();

    if (!csrfToken) {
      goToErrorPage({ message: "expiredLogin", status: 403, useDom: true });
    }

    return csrfTokenToReturn;
  }
}

const csrfToken = new CsrfToken();

export default csrfToken;
