import "./ChangeAmount.css";
import { InfoIcon, PlusOrMinusIcon } from "../../UI/Icons";
import { useCartStore } from "../../../Zustand";

type Props = {
  item_code: string;
  handleInfoClick: () => void;
};

const ChangeAmount = ({ item_code, handleInfoClick }: Props) => {
  const { cart, updateCartItemAmount } = useCartStore();

  const handleChangeAmount = (amount: 1 | -1) => {
    updateCartItemAmount(item_code, amount);
  };

  return (
    <div className="product-amount-wrapper">
      <div className="product-amount-wrapper_info">
        <InfoIcon size="2rem" color="var(--secondary-color)" clickable onClick={handleInfoClick} />
      </div>
      <div
        tabIndex={0}
        className="amount-changer"
        onKeyDown={(e) => e.key === "Enter" && handleChangeAmount(1)}
        onClick={() => handleChangeAmount(1)}
      >
        <PlusOrMinusIcon color="var(--primary-color)" sign="plus" size={"4rem"} />
      </div>
      <div aria-label={cart[item_code]?.amount.toString() || "0"} className="amount">
        {cart[item_code]?.amount || 0}
      </div>
      <div
        onKeyDown={(e) => e.key === "Enter" && handleChangeAmount(-1)}
        tabIndex={0}
        className="amount-changer"
        onClick={() => handleChangeAmount(-1)}
      >
        <PlusOrMinusIcon color="var(--primary-color)" sign="minus" size={"4rem"} />
      </div>
    </div>
  );
};

export default ChangeAmount;
