import { localStorageKeys } from "../../hooks/useLocalStorage";
import { Cart, CartItem, OnlineStoresType, ProductDataType, PromotionTable, StoreEn } from "../../types/types";

class PriceCalculator {
  private static hasClub = (store: StoreEn) => {
    const onlineStoreType: OnlineStoresType = JSON.parse(window.localStorage.getItem(localStorageKeys.userOnlineStores) ?? "[]");

    return onlineStoreType.hasClubCard.includes(store);
  };

  private static applyPromotion(
    store: StoreEn,
    item: CartItem,
    promotion: PromotionTable,
    cartTotalWithoutPromo: number,
    amountOfItemsWithSamePromoId: number
  ): number {
    const { amount, product } = item;
    const { item_price } = product[store];
    const { discounted_price, min_purchase_amount, min_qty, max_qty, club_id } = promotion;

    // promo is for club user
    if (club_id && club_id[0] !== 0 && !this.hasClub(store)) {
      return item_price * amount;
    }

    // Check min_purchase rule
    if (min_purchase_amount && cartTotalWithoutPromo < min_purchase_amount) {
      return item_price * amount;
    }

    // Check min_qty rules
    if (amountOfItemsWithSamePromoId < min_qty) {
      return item_price * amount;
    }

    // Apply min_qty and include  max_qty rule
    if (amountOfItemsWithSamePromoId >= min_qty) {
      // max rule logic
      const max_qty_applicable = max_qty && max_qty > 0 ? max_qty : amountOfItemsWithSamePromoId;
      let remainingItems = 0;
      if (amountOfItemsWithSamePromoId - max_qty_applicable > 0) {
        remainingItems = amountOfItemsWithSamePromoId - max_qty_applicable;
      }

      // min rule logic
      const totalItemsAmount = Math.min(amountOfItemsWithSamePromoId, max_qty_applicable);
      const totalNormalItemsReminder = totalItemsAmount % min_qty;
      const discountedTimes = Math.floor(totalItemsAmount / min_qty);

      const totalDiscountedPrice = discountedTimes * discounted_price;
      const totalNormalPrice = (totalNormalItemsReminder + remainingItems) * item_price; // including remaining from max rule

      const relativeAmount = amount / amountOfItemsWithSamePromoId;
      return relativeAmount * (totalNormalPrice + totalDiscountedPrice);
    }

    throw new Error("Price didnt calculated ");
  }

  private static calculateCartTotalWithoutCurrentPromo(store: StoreEn, cart: Cart, excludedPromoId: string): number {
    return Object.keys(cart).reduce((total, key) => {
      const cartItem = cart[key];
      if (!cartItem.product[store]?.promotions?.some(p => p.promotion_id === excludedPromoId)) {
        total += this.getItemPrice(store, cartItem, cart, true);
      }
      return total;
    }, 0);
  }

  private static getAmountOfItemsWithSamePromoId(store: StoreEn, cart: Cart, promoId: string): number {
    return Object.keys(cart).reduce((total, key) => {
      const { product, amount } = cart[key];
      const promotions = product[store]?.promotions;

      if (promotions) {
        promotions.forEach(p => {
          if (p.promotion_id === promoId) {
            total += amount;
          }
        });
      }
      return total;
    }, 0);
  }

  static getItemPrice(store: StoreEn, item: CartItem, cart: Cart, isSubCalculation: boolean = false): number {
    if (!item.product[store]) return 0;

    const { amount, product } = item;
    const { item_price, promotions } = product[store];

    if (!promotions || promotions.length === 0) {
      return item_price * amount;
    }

    let bestPrice = item_price * amount;

    for (const promotion of promotions) {
      // Calculate cart total without items with the same promotion ID
      const cartTotalWithoutPromo = isSubCalculation ? 0 : this.calculateCartTotalWithoutCurrentPromo(store, cart, promotion.promotion_id);

      const amountOfItemsWithSamePromoId = this.getAmountOfItemsWithSamePromoId(store, cart, promotion.promotion_id);
      const promoPrice = this.applyPromotion(store, item, promotion, cartTotalWithoutPromo, amountOfItemsWithSamePromoId);

      if (promoPrice < bestPrice) {
        bestPrice = promoPrice;
      }
    }

    return bestPrice;
  }

  static getTotalCartCost(store: StoreEn, cart: Cart): number {
    return Object.values(cart).reduce((total, item) => {
      if (!item.product[store]) return total;
      return (total += this.getItemPrice(store, item, cart));
    }, 0);
  }

  static getItemPriceToString(store: StoreEn, item: CartItem, cart: Cart): string {
    const itemPrice = this.getItemPrice(store, item, cart);
    return itemPrice.toFixed(2);
  }

  // TODO: NO longer in use (was on v1) - but dont delete yet
  // static getItemPriceRange({ stores, item, cart }: { stores: StoreEn[]; item: CartItem; cart: Cart }): string {
  //   const priceRanges: number[] = [];

  //   stores.forEach((store) => {
  //     priceRanges.push(this.getItemPrice(store, item, cart));
  //   });

  //   const minPrice = Math.min(...priceRanges).toFixed(2);
  //   const maxPrice = Math.max(...priceRanges).toFixed(2);

  //   return `${minPrice} - ${maxPrice}`;
  // }

  // TODO: NO longer in use (was on v1) - but dont delete yet
  // static getCartCostRange({ stores, cart }: { stores: StoreEn[]; cart: Cart }) {
  //   const priceRanges: number[] = [];

  //   stores.forEach((store) => {
  //     priceRanges.push(this.getTotalCartCost(store, cart));
  //   });

  //   const minPrice = Math.min(...priceRanges).toFixed(2);
  //   const maxPrice = Math.max(...priceRanges).toFixed(2);

  //   return `${minPrice} - ${maxPrice}`;
  // }

  static getNormalPriceRange(stores: StoreEn[], product: ProductDataType) {
    const pricesList = stores.map((store: StoreEn) => {
      return product[store].item_price;
    });

    const max = Math.max(...pricesList);
    const min = Math.min(...pricesList);

    return `${min}-${max}`;
  }

  static RoundPrice(price: number) {
    return Math.round((price + Number.EPSILON) * 100) / 100;
  }
}

export default PriceCalculator;
