import CategoriesMap, { CategoriesAndSub } from "../assets/Data/categories";
import imageNoImage from "../assets/images/Image_not_available.png";
import { ListIcon } from "../components/UI/Icons";
import { comparisonIndicators } from "../pages/CartComparisonPage/Helpers/Ui";
import { CartSource, CookieName, Language, StoreEn, User } from "../types/types";

// register , login
export const parseCookies = (cookieName: CookieName) => {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return value;
    }
  }
};

export const handleImageError = async (event: any, size?: "small" | "medium") => {
  event.target.src = imageNoImage;

  if (size && size === "small") {
    event.target.width = 75;
    event.target.height = 75;
  } else if (size && size === "medium") {
    event.target.height = 150;
    event.target.width = 150;
  }
};

export const directionByLanguage = (language: Language) => {
  if (["arabic", "hebrew"].includes(language)) return "rtl";
  return "ltr";
};

function isWordWithLettersAndNumbers(word: string) {
  return /\d/.test(word) && /[א-ת]/.test(word);
}

function addSpacing(word: string) {
  return word.replace(/([א-ת])(\d)/g, "$1 $2").replace(/(\d)([א-ת])/g, "$1 $2");
}

export function addSpacingBetweenNumbersAndLetters(text: string) {
  if (!text) return "";
  const strArr = text.split(" ");
  for (let i = 0; i < strArr.length; i++) {
    if (isWordWithLettersAndNumbers(strArr[i])) {
      strArr[i] = addSpacing(strArr[i]);
    }
  }
  return strArr.join(" ");
}

export const parseJwt = <T>(token: string, camelCase = true) => {
  try {
    if (!token) return null;
    const payload = token.split(".")[1];
    const decodedPayload = JSON.parse(
      decodeURIComponent(
        atob(payload)
          .split("")
          .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      )
    );

    if (camelCase) {
      // Convert snake_case keys to camelCase
      const camelCasedPayload = Object.keys(decodedPayload).reduce((acc, key) => {
        const camelKey = snakeToCamel(key);
        acc[camelKey] = decodedPayload[key];
        return acc;
      }, {} as Record<string, any>);
      return camelCasedPayload as T;
    }

    return decodedPayload;
  } catch (e) {
    return null;
  }
};

export const checkTokenExpIsValid = (decodedJwt: { [key: string]: any } | null) => {
  const exp = decodedJwt?.exp;
  if (!exp || exp * 1000 < Date.now()) {
    // token expired
    return false;
  }
  return true;
};

export const snakeToCamel = (str: string) => {
  return str.replace(/_([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
};

//register
export function isValidIsraelPhoneNumber(phoneNumber: string) {
  const israelPhoneNumberPattern = /^05\d{8}$/;
  return israelPhoneNumberPattern.test(phoneNumber);
}

export const updateUserContextFromSnakeToCamelCase = (initialSUserStateStorage: any) => {
  const initialSUserState: User = {
    isUser: false,
    city: null,
    email: "guest",
    phoneNumber: undefined,
    fullName: "Guest",
    language: "hebrew" as Language
  };

  for (const key in initialSUserStateStorage) {
    const camelKey = snakeToCamel(key) as keyof User;
    if (Object.prototype.hasOwnProperty.call(initialSUserState, camelKey)) {
      (initialSUserState as any)[camelKey] = initialSUserStateStorage[key];
    }
  }

  return initialSUserState;
};

export const LangResolvePosition = (language?: Language) => {
  if (!language) return "left";
  return ["hebrew", "arabic"].includes(language) ? "left" : "right";
};

export const resolveDirClass = (language: Language) => {
  return ["hebrew", "arabic"].includes(language) ? "rtl" : "ltr";
};

export const getTranslatedCategory = (language: Language) => {
  const translatedCategories: any = {};
  for (const category of CategoriesMap) {
    for (const sub of category.sub_categories) {
      translatedCategories[sub.id] = sub[language]; // You can change 'hebrew' to the desired language
    }
  }
  return translatedCategories;
};

export const findCategoryObject = (categoryId: number) => {
  const unknown: CategoriesAndSub = {
    id: -1,
    hebrew: "???",
    english: "???",
    russian: "???",
    arabic: "???",
    icon: ListIcon,
    sub_categories: [
      {
        id: -1,
        english: "?",
        hebrew: "?",
        arabic: "?",
        russian: "?"
      }
    ]
  };

  const refCategory = CategoriesMap.find(c => c.id === categoryId) || unknown;
  // deep-clone
  // return structuredClone(refCategory) as CategoriesAndSub;
  // return Object.assign({}, refCategory);
  return JSON.parse(JSON.stringify(refCategory)) as CategoriesAndSub;
};

export const findSubCategoryObject = (categoryId: number, subCategoryId: number) => {
  const category = findCategoryObject(categoryId);

  const sub = category.sub_categories.find(sub => sub.id === subCategoryId);
  if (sub) return { ...sub };

  const unknown = findCategoryObject(-1);
  const unknownSub = unknown.sub_categories.find(sub => sub.id === -1);

  return unknownSub;
};

export const sortStr = (strA: string, strB: string) => {
  if (strA < strB) {
    return -1;
  } else if (strA > strB) {
    return 1;
  } else {
    return 0;
  }
};

export function serializeParams(params: Record<string, any>) {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${typeof value === "object" ? JSON.stringify(value) : value}`)
    .join("&");
}

export const resolveImgUrl = (store: StoreEn, imgUrlPrev?: string) => {
  if (!imgUrlPrev) return "";

  if (store === "rami") {
    const ramiDomain = "https://img.rami-levy.co.il";
    if (imgUrlPrev.startsWith(ramiDomain)) return imgUrlPrev;
    return ramiDomain + imgUrlPrev;
  }

  if (imgUrlPrev.startsWith("https://")) return imgUrlPrev;

  return imgUrlPrev;
};

export function uniqueArray<T>(arr: T[], keyExtractor?: (i: T) => string) {
  if (keyExtractor) {
    const seen = new Set<string>();

    return arr.filter(item => {
      const key = keyExtractor(item);
      if (seen.has(key)) {
        return false;
      }

      seen.add(key);
      return true;
    });
  }

  return arr.filter((item, idx, self) => {
    const sameIdx = self.findIndex(v => JSON.stringify(v) === JSON.stringify(item));
    return idx === sameIdx;
  });
}

export const drawMatchScoringIcon = (source?: CartSource) => {
  switch (source) {
    case "auto-code":
      return comparisonIndicators.perfect.icon;

    case "auto-similarity":
      return comparisonIndicators.similar.icon;

    case "FE-user-select":
      return comparisonIndicators.custom.icon;
    default:
      const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      // default is unknown or user products table
      if (regex.test(source ?? "")) {
        return comparisonIndicators.usersCustom.icon;
      }

      return "todo";
  }
};
