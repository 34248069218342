import { ReactNode, useCallback, useState } from "react";
import { Flex, Tooltip, Typography } from "../../../components/UI";
import { InfoIcon, CheckVXICon, EditIcon, GroupIcon } from "../../../components/UI/Icons";
import { StoreEn } from "../../../types/types";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { dictStores } from "../../../dictionary/dictStores";
import AlertMessage from "../../../components/UI/AlertMessage/AlertMessage";

interface IDrawCartFooterLine {
  toolTipTitle?: string;
  description: string;
  price?: string;
  missingItems?: string[];
  dataTour?: string;
  store?: StoreEn;
}

export const DrawCartFooterLine = ({ dataTour, toolTipTitle, description, price, missingItems, store }: IDrawCartFooterLine) => {
  return (
    <Flex dataTour={dataTour} cssClass="cart-comparison_cart_footer_box" justify="space-between" align="center" gap={5}>
      {toolTipTitle && (
        <span>
          <Tooltip title={toolTipTitle} />{" "}
        </span>
      )}

      {missingItems && <MissingItems store={store} missingItems={missingItems} />}
      <span>{description}</span>

      {price && (
        <div>
          <span>{price}</span>
          <span style={{ fontSize: 12 }}> ₪</span>
        </div>
      )}
    </Flex>
  );
};

const MissingItems = ({ store, missingItems }: { store?: StoreEn; missingItems?: string[] }) => {
  const [message, setMessage] = useState<ReactNode>(null);

  if (!missingItems || !missingItems.length) return null;

  const handleMessage = () => {
    if (message) {
      setMessage(null);
      return;
    }

    const messageToDraw = (
      <Flex
        className="scrollable"
        style={{ maxHeight: 250, maxWidth: 500, overflow: "auto", padding: "10px" }}
        flexDirection="column"
        gap={15}
      >
        {missingItems.map((item, i) => {
          return (
            <Flex className="rtl">
              <span>{i + 1}</span>
              <span>. </span>
              <Typography.Text key={item}>{item}</Typography.Text>
            </Flex>
          );
        })}
      </Flex>
    );

    setMessage(messageToDraw);
  };

  return (
    <>
      <InfoIcon cssClass="data-tour-info" clickable onClick={handleMessage} />
      {message && (
        <AlertMessage
          style={{ background: "var(--white)", border: "none" }}
          showIcon={true}
          title={`פריטים חסרים ב ${dictStores[store!].hebrew} (${missingItems.length})`}
          message={message}
          type="info"
          delay={6}
          position="top-left"
          onMessageEnd={() => setMessage(null)}
        />
      )}
    </>
  );
};

export const CartComparisonLegend = () => {
  const {
    user: { language }
  } = useCustomContext();

  const drawLegendIcon = useCallback(() => {
    return Object.values(comparisonIndicators).map((i, idx) => {
      return (
        <Flex gap={5} key={idx} align="center" style={{ lineHeight: "20px" }} responsive={{ sm: { flex: 1, width: "100%" } }}>
          {i.icon}
          <span>{i[language]}</span>
        </Flex>
      );
    });
  }, [language]);

  return (
    <Flex gap={15} align="center" justify="center" wrap="wrap" responsive={{ sm: { flexDirection: "column" } }}>
      {drawLegendIcon()}
    </Flex>
  );
};

export const comparisonIndicators = {
  perfect: {
    icon: <CheckVXICon shape="v" size="20px" cssClass="data-tour-icon-match" color="green" />,
    english: "Same product",
    russian: "Тот же продукт",
    arabic: "نفس المنتج",
    hebrew: "מוצר זהה לחלוטין"
  },
  usersCustom: {
    icon: <GroupIcon size="20px" cssClass="data-tour-icon-replaced" />,
    english: "Popular match",
    russian: "Популярное совпадение ",
    arabic: "مُطَابَقَة شَعْبِيَّة",
    hebrew: "התאמה פופולרית"
  },
  custom: {
    icon: <EditIcon size="20px" cssClass="data-tour-icon-replace" />,
    english: "Custom matching",
    russian: "Индивидуальный подбор",
    arabic: "مطابقة مخصصة",
    hebrew: "התאמה ידנית שביצעתי בקנייה זו"
  },
  similar: {
    icon: <CheckVXICon shape="v" size="20px" cssClass="data-tour-icon-similar" color="blue" />,
    english: "Similar product",
    russian: "Похожий продукт",
    arabic: "منتج مشابه",
    hebrew: "מוצר דומה"
  },
  notFound: {
    icon: <CheckVXICon shape="x" color="red" size="20px" cssClass="data-tour-icon-error" />,
    english: "We couldn't match it",
    russian: "Мы не смогли подобрать",
    arabic: "لم نستطع مطابقته",
    hebrew: "לא מצאנו התאמה - נסו אתם"
  }
};
