import { useState } from "react";
import SearchProduct from "./SearchProduct/SearchProduct";
import SiteNavOptions from "./SiteNavOptions/SiteNavOptions";
import { Link } from "react-router-dom";
import HamburgerButton from "../../UI/HamburgerBtn/Hamburger";
import Logo from "../../Logo/Logo";
import useSelectedItem from "../../../Zustand/stores/useSelectedItem";
import { ProductDataType } from "../../../types/types";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";

const SiteNav = () => {
  const {
    user: { language },
    onlineStores: { favoriteStore }
  } = useCustomContext();

  const [isHamburgerOpen, setIsHamburger] = useState<boolean>();
  const { setSelectedProduct } = useSelectedItem();

  const handleSelectedFromSearch = (item: ProductDataType) => {
    setSelectedProduct({ item, selectedStoreItem: favoriteStore, feature: "main", trigger: "mainSearch" });
  };

  return (
    <nav>
      <ul className="site_nav_container rtl">
        <li className="site_nav_box">
          <Link to={"/"}>
            <Logo
              color="currentColor"
              cssClass="site-nav_main-logo-wrapper"
              fontSize={30}
              useResponsiveHiddenTextA
              useResponsiveHiddenTextB
            />
          </Link>
        </li>

        {!isHamburgerOpen && (
          <li className="site_nav_box site_nav_box-primary responsive-flex-3 anim-fade-in">
            <SearchProduct onSelected={handleSelectedFromSearch} language={language} store={favoriteStore} />
          </li>
        )}

        <SiteNavOptions isHamburgerOpen={isHamburgerOpen} />

        <li onClick={() => setIsHamburger(p => !p)} className={`site_nav_hamburger ${isHamburgerOpen ? "" : "desktop-display-none"}`}>
          <HamburgerButton />
        </li>
      </ul>
    </nav>
  );
};

export default SiteNav;
