import "./Homepage.css";
import Cover from "./Cover/Cover";
import HowItsWork from "./HowItsWork/HowItsWork";
import CategoriesPricesChartsCarousel from "./CategoriesPricesChartsCarousel/CategoriesPricesChartsCarousel";
import Tutorial from "./Tutorial/Tutorial";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import DrawerCart from "../../components/Cart/DrawerCart/DrawerCart";
import SelectedProduct from "../../components/SelectedProduct/SelectedProduct";

const Homepage = () => {
  const {
    user,
    onlineStores: { favoriteStore },
  } = useCustomContext();

  return (
    <div id="hp-v3-container">
      <div className="space-height-lg"></div>
      <Cover isUser={user.isUser} />
      <div className="space-height-sm"></div>
      <CategoriesPricesChartsCarousel title={"השוואת קטגוריות"} showIcons={true} language={user.language} />
      <div className="space-height-xxl"></div>
      <div className="space-height-xxl"></div>
      <div className="space-height-xxl"></div>
      <HowItsWork />
      <div className="space-height-xxl"></div>
      <div className="space-height-xxl"></div>
      <div className="space-height-xl"></div>
      <Tutorial />
      <div className="space-height-xxl"></div>
      <div className="space-height-xxl"></div>

      <DrawerCart />
      <SelectedProduct store={favoriteStore} />
    </div>
  );
};

export default Homepage;
