import { useMemo, useState } from "react";
import { Flex, SelectSearchOptionType } from "../../../UI";
import "./AdminMatchingProducts.css";
import MatchingForm from "./MatchingForm/MatchingForm";
import MatchingGrid from "./MatchingGrid/MatchingGrid";
import { ProductDataTypeNullable, StoreEn } from "../../../../types/types";
import { CartComparisonLegend } from "../../../../pages/CartComparisonPage/Helpers/Ui";

export type fetchedProductsDataType = Record<string, ProductDataTypeNullable>;

export type formFetchDataType = {
  favoriteStore: SelectSearchOptionType[];
  otherStores: SelectSearchOptionType[];
  subCategory: SelectSearchOptionType[];
  category: SelectSearchOptionType[];
  products: fetchedProductsDataType;
  prevFetchOptions: string;
  filterSameItemCode: boolean;
  filterNameSimilarity: boolean;
};

const AdminMatchingProducts = () => {
  const [formData, setFormData] = useState<formFetchDataType>({
    favoriteStore: [],
    otherStores: [],
    subCategory: [],
    category: [],
    products: {},
    prevFetchOptions: "",
    filterSameItemCode: true,
    filterNameSimilarity: false
  });

  const [isLoading, setIsLoading] = useState(false);

  const renderStores = useMemo(() => {
    return [...formData.favoriteStore, ...formData.otherStores].map(i => ({
      label: i.label,
      id: i.data.id,
      name: i.data.name as StoreEn
    }));
  }, [formData.favoriteStore, formData.otherStores]);

  return (
    <Flex id="product_matching_container" flexDirection="column" gap={20}>
      <MatchingForm formData={formData} setFormData={setFormData} toggleLoading={setIsLoading} />
      <MatchingGrid formData={formData} setFormData={setFormData} isLoading={isLoading} stores={renderStores} />

      <CartComparisonLegend />
    </Flex>
  );
};

export default AdminMatchingProducts;
