import { FC } from "react";
import "./SpinnerA.css";
import Typography from "../../Typography/Typography";

type Props = {
  size: number;
  margin?: string;
  text?: string;
  absolute?: boolean;
};

const SpinnerA: FC<Props> = ({ size, margin, text, absolute }) => {
  const spinnerStyle = {
    width: size,
    height: size,
    border: `${size / 10}px solid var(--light-p-color)`,
    borderTopColor: "var(--dark-color)",
    borderRadius: "50%",
    display: "inline-block",
  };

  return (
    <div className={`spinner-container ${absolute ? "spinner-absolute" : ""}`} style={{ margin: margin || undefined }}>
      <div className="spinner" style={spinnerStyle}></div>
      {/* <span className="loader"></span> */}
      {text && <Typography.Text>{text}</Typography.Text>}
    </div>
  );
};

export default SpinnerA;
