import { ThemeType } from "../../../components/Accessability/AccessabilityContent/AccesabilityContentOptions/AccessabilityOption";
import { OnlineStoresType, User } from "../../../types/types";
import { parseJwt, updateUserContextFromSnakeToCamelCase } from "../../../utils/utils";

export const initialSUserState: User = {
  // id: "guest",
  isUser: false,
  city: null, //getbygeoLocation
  email: "guest",
  phoneNumber: undefined,
  fullName: "Guest",
  // favoriteStore: "victory",
  // otherStores: ["bitan", "mahsane", "rami"],
  // hasClubCard: [],
  language: "hebrew"
};

export const onlineStoresInitialValue: OnlineStoresType = {
  favoriteStore: "victory",
  otherStores: ["bitan", "mahsane", "rami"],
  hasClubCard: []
};

export const getUserInitialValue = (token?: string) => {
  if (token) {
    const initialSUserStateStorage = parseJwt(token as string);
    if (initialSUserStateStorage.email !== "guest") initialSUserStateStorage.isUser = true;
    return updateUserContextFromSnakeToCamelCase(initialSUserStateStorage);
  }

  return initialSUserState;
};

export const ThemeInitialValue: ThemeType = {
  contrast: "theme-light",
  fontSize: "theme-font-normal",
  wordSpacing: "theme-word-spacing-normal",
  showLinksUnderline: "",
  showAnimation: ""
};
