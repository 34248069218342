import { CSSProperties, ReactNode, FC } from "react";
import "./Button.css";

import SpinnerA from "../Loaders/spinnerA/SpinnerA";

type btnVariantType = "primary" | "secondary" | "contrast" | "success" | "info" | "gray" | "bgs" | "danger" | "warning";

export type ButtonProps = {
  children: ReactNode;
  onClick?: (e: React.MouseEvent<any, MouseEvent>) => void;
  size?: "small" | "medium" | "large";
  color?: string;
  backgroundColor?: string;
  cssClass?: string;
  disabled?: boolean;
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
  variant?: btnVariantType;
  type?: "primary" | "default" | "dashed";
  htmlType?: "button" | "reset" | "submit";
  loading?: boolean;
  ariaLabel?: string;
  dataTour?: string;
  title?: string;
};

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  variant = "primary",
  size = "medium",
  color,
  backgroundColor,
  cssClass = "",
  disabled = false,
  width = "100%",
  height,
  style,
  type = "primary",
  htmlType,
  loading = false,
  ariaLabel,
  dataTour,
  title
}) => {
  const style2: CSSProperties = {
    backgroundColor: backgroundColor || undefined,
    color: color || undefined,
    width: width || undefined,
    display: width ? "block" : undefined, // Set display to block if width is provided
    margin: width ? "0 auto" : undefined, // Center if width is set
    height: height || undefined
  };

  return (
    <button
      data-tour={dataTour}
      tabIndex={0}
      className={`${cssClass} button btn-${variant} btn-${type} ${size}`}
      onClick={onClick}
      style={{ ...style2, ...style }}
      disabled={disabled || loading}
      type={htmlType}
      aria-label={ariaLabel}
      title={title}
    >
      {loading ? <SpinnerA size={25} /> : children}
    </button>
  );
};

export default Button;
