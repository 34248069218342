import serverAPI from "../../../api/server";
import { goToErrorPage } from "../../../components/ErrorPage/helpers/errorPageHelper";
import csrfToken from "../../../models/Tokens/CsrfToken";
import { User } from "../../../types/types";
import { checkTokenExpIsValid, parseJwt, updateUserContextFromSnakeToCamelCase } from "../../../utils/utils";
import { redirect } from "react-router-dom";

export const autoLoginNewUserLoader = async (req: any, contextValues: any) => {
  const token = req.params.token;

  const decoded = parseJwt<User>(token);

  if (!decoded) {
    const path = goToErrorPage({ message: "unauthorized", status: 401 });
    return redirect(path);
  }

  const isValid = checkTokenExpIsValid(decoded);

  if (!isValid) {
    const path = goToErrorPage({ message: "expiredAction", status: 401 });
    return redirect(path);
  }

  const loginReq = await serverAPI("users/login", { email: decoded.email, password: decoded.password! });

  if (loginReq.statusCode === 200) {
    const { setUser } = contextValues;
    delete decoded.password;

    csrfToken.setToken(loginReq.data.csrfToken);
    const parsedUser = updateUserContextFromSnakeToCamelCase(decoded);
    parsedUser.isUser = true;
    setUser(parsedUser);
  }

  return redirect("/");
};
