import { ReactNode, CSSProperties } from "react";
import "./Card.css";
import useResponsiveStyles, { ResponsiveStyles } from "../hooks/useMediaQuery";

interface ICardProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  children: string | ReactNode | ReactNode[];
  footer?: string | ReactNode | ReactNode[];
  image?: { src: string | any; width?: string | number; height?: string | number };
  iconR?: ReactNode;
  iconL?: ReactNode;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  onClick?: () => void;
  cssClass?: string;
  shadow?: boolean;
  radius?: string | number;
  hover?: boolean;
  onScroll?: (e: any) => void;
  dataTour?: string;
  variantColor?: "" | "blue";
  responsive?: ResponsiveStyles;
}

const Card = ({
  cssClass = "",
  title,
  subtitle,
  children,
  footer,
  image,
  iconR,
  iconL,
  style,
  contentStyle,
  onClick,
  onScroll,
  shadow = true,
  hover = false,
  radius,
  dataTour,
  responsive,
  variantColor = ""
}: ICardProps) => {
  const dynamicStyle = useResponsiveStyles(responsive);

  return (
    <div
      data-tour={dataTour}
      className={`card card-variant-${variantColor} ${shadow ? "card-shadow" : ""} ${hover ? "card-shadow-hover" : ""} ${
        !radius ? "card-radius" : ""
      } ${cssClass}`}
      style={{ borderRadius: radius ? radius : undefined, ...style, ...dynamicStyle }}
      onClick={onClick}
      onScroll={onScroll}
    >
      {image && <img src={image.src} height={image.height} width={image.width} alt="Card" className="card-image" />}
      {iconR && <div className="card-icon card-icon-right">{iconR}</div>}
      {iconL && <div className="card-icon card-icon-left">{iconL}</div>}
      {title && <h4 className="card-title">{title}</h4>}
      {subtitle && typeof subtitle === "string" ? <h5 className="card-subtitle">{subtitle}</h5> : subtitle}
      <div className="card-content" style={contentStyle}>
        <div className="card-body">{children}</div>
      </div>
      {footer && <div className="card-footer">{footer}</div>}
    </div>
  );
};

export default Card;
