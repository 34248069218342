import React from "react";
import { Flex, ImageComponent, Typography } from "../../../UI";
import { resolveImgUrl } from "../../../../utils/utils";
import { StoreEn } from "../../../../types/types";

interface IProps {
  itemName: string;
  itemPrice: number;
  selectedStore: StoreEn;
  imgUrl: string;
}

const SelectedMainFeatureContent = ({ itemName, itemPrice, selectedStore, imgUrl }: IProps) => {
  return (
    <>
      <Flex flexDirection="column" align="center" style={{ position: "sticky", top: 0 }}>
        <Typography.Title align="center" level={2}>
          {itemName}
        </Typography.Title>
        <Typography.Text secondary>
          <span> {itemPrice}</span>
          <span>&#8362;</span>
        </Typography.Text>
      </Flex>
      <div style={{ position: "sticky", top: 30 }}>
        <ImageComponent width={200} height={200} src={resolveImgUrl(selectedStore, imgUrl)} alt={itemName} />
      </div>
    </>
  );
};

export default SelectedMainFeatureContent;
