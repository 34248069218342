import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { dictStores } from "../../../../../../dictionary/dictStores";
import { ProductDataType } from "../../../../../../types/types";
import { drawMatchScoringIcon, resolveImgUrl } from "../../../../../../utils/utils";
import SearchProduct from "../../../../../Navbar/SiteNav/SearchProduct/SearchProduct";
import SelectedProductInfoTab from "../../../../../SelectedProduct/Tabs/SelectedProductInfoTab";
import { ButtonProps, Card, Flex, ImageComponent, Modal, Tooltip, Typography } from "../../../../../UI";
import { AdminSelectedItemType } from "../MatchingGrid";
import serverAPI from "../../../../../../api/server";
import OnlineStoresDict from "../../../../../../utils/onlineStoresDict";
import { formFetchDataType } from "../../AdminMatchingProducts";
import { UsersProductsEP } from "../../../../../../api/features/usersProducts.ep.types";
import { categoryBreadCrumb } from "../../../../../ProductSearchItemCard/ProductSearchItemCard";

type AdminActionType = {
  info?: string;
  btn: ButtonProps;
  visible: boolean;
};

const AdminSelectedModal = ({
  selectedItem,
  onCloseModal,
  setFormData,
  onSelected
}: {
  setFormData: Dispatch<SetStateAction<formFetchDataType>>;
  selectedItem: AdminSelectedItemType;
  onCloseModal: () => void;
  onSelected: (selected: ProductDataType) => void;
}) => {
  const handleDelete = useCallback(async () => {
    const { ref, selected } = selectedItem;
    if (!ref || !selected?.data) return;
    const refStoreId = OnlineStoresDict.getOnlineStoreIdFromName(ref.name);
    const selectedStoreId = OnlineStoresDict.getOnlineStoreIdFromName(selected.name);

    const res = await serverAPI("products-matchings/admin/delete/single", {
      favoriteProductItemCode: ref!.data.item_code,
      favoriteStoreId: refStoreId,
      storeId: selectedStoreId,
      subCategory: ref.data.sub_category,
      storeProductItemCode: selected.data.item_code
    });

    console.log(res);

    setFormData(prev => {
      const prevProducts = { ...prev.products };
      delete prevProducts[ref.data.item_code][selected.name];
      return { ...prev, products: prevProducts };
    });

    onCloseModal();
  }, [selectedItem, setFormData, onCloseModal]);

  // same logic as in 'loginToStoreModal' in comparison page
  const customUserProductsUpdate = useCallback(async () => {
    const { ref, adminSuggestion } = selectedItem;
    if (!ref || !adminSuggestion) return;
    const refStoreId = OnlineStoresDict.getOnlineStoreIdFromName(ref.name);
    const selectedStoreId = OnlineStoresDict.getOnlineStoreIdFromName(adminSuggestion.name);

    const customProductsBody: UsersProductsEP["products-matchings/basic/update"]["body"] = [
      {
        subCategory: ref.data.sub_category,
        favoriteStoreId: refStoreId,
        storeId: selectedStoreId,
        favoriteProductItemCode: ref.data.item_code,
        storeProductItemCode: adminSuggestion.data.item_code
      }
    ];

    const res = await serverAPI("products-matchings/basic/update", customProductsBody);
    console.log(res);

    setFormData(prev => {
      const prevProducts = { ...prev.products };
      prevProducts[ref.data.item_code][adminSuggestion.name] = adminSuggestion.data;
      return { ...prev, products: prevProducts };
    });
    onCloseModal();
  }, [selectedItem, setFormData, onCloseModal]);

  const adminActions: Record<"delete" | "replace", AdminActionType> = useMemo(() => {
    return {
      replace: {
        info: "החלפה- החלפת ההתאמה הקיימת בבחירה של האדמין",
        visible: !!selectedItem.adminSuggestion?.name,
        btn: { children: "החלפת התאמה", type: "primary", variant: "secondary", onClick: customUserProductsUpdate }
      },
      delete: {
        info: "מחיקה - מחיקת ההתאמה עבור פריט זה בין 2 החנויות המוצגות",
        visible:
          !!selectedItem.selected?.data &&
          selectedItem.ref?.data.item_code !== selectedItem.selected.data.item_code &&
          selectedItem.selected.data.source !== "auto-similarity",

        btn: { children: "מחיקת התאמה קיימת", variant: "danger", onClick: handleDelete }
      }
    };
  }, [selectedItem, handleDelete, customUserProductsUpdate]);

  if (!selectedItem.ref) return null;

  const drawItemCard = (item: AdminSelectedItemType["selected"], source: Exclude<keyof AdminSelectedItemType, "isOpen">) => {
    if (!item || !item?.data) return null;

    // @ts-ignore
    item.data.promotions = null;

    let borderType = "dashed";
    let opacity = 1;

    if ((selectedItem.adminSuggestion && source === "adminSuggestion") || source === "ref") {
      borderType = "solid";
    } else if (selectedItem.adminSuggestion) {
      opacity = 0.6;
    }

    const sourceDict: Record<typeof source, { text: string; color: string }> = {
      ref: {
        text: "מוצר מקור - חנות ראשית",
        color: "green"
      },
      adminSuggestion: {
        text: "הצעת מנהל - להחלפה",
        color: "orange"
      },
      selected: {
        color: "red",
        text: "מוצר לבדיקה - חנות נבחרת"
      }
    };

    return (
      <Flex flexDirection="column" align="center">
        <Typography.Title level={3}>{dictStores[item?.name].hebrew}</Typography.Title>
        <Card
          cssClass="admin-selected-source-card"
          style={{ height: "100%", border: `3px ${borderType} var(--border-color)`, opacity }}
          // subtitle={}
          title={
            <div
              className=""
              style={{
                textAlign: "center",
                color: "white",
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                height: 50,
                background: sourceDict[source].color
              }}
            >
              <div style={{ width: "85%", textAlign: "center" }}>{sourceDict[source].text}</div>
            </div>
          }
          // title={source}
          iconL={<Tooltip title={item.data.source}>{drawMatchScoringIcon(item.data.source)}</Tooltip>}
        >
          <Flex flexDirection="column" gap={10}>
            <Typography.Text align="center" color="var(--primary-color)" strong>
              {selectedItem[source]?.data?.item_name}
            </Typography.Text>
            <Typography.Text align="center" secondary strong>
              {categoryBreadCrumb(item.data, "hebrew")}
            </Typography.Text>
            <ImageComponent src={resolveImgUrl(item.name, item?.data.img_url)} alt="xxx" width={200} height={200} zoomSize={"lg"} />
            <SelectedProductInfoTab productData={item.data} style={{ width: 300 }} />
          </Flex>
        </Card>
      </Flex>
    );
  };

  const handleSelectedItem = (selected: ProductDataType) => {
    const store = selectedItem.selected!.name;
    selected[store].source = "FE-user-select";
    onSelected(selected);
  };

  const drawNotFoundItem = () => {
    if (selectedItem.selected?.data || !selectedItem.selected) {
      return null;
    }
    return (
      <Card title={dictStores[selectedItem.selected.name].hebrew}>
        <Flex align="center">
          <div className="cart-item_not_found_in_cart_img"></div>
          <Typography.Title align="center" level={4}>
            אין התאמה לפריט זה בחנות זו - בצע חיפוש והחלפה
          </Typography.Title>
        </Flex>
      </Card>
    );
  };

  return (
    <Modal
      draggable
      visible={selectedItem?.isOpen}
      title={selectedItem.ref!.data.item_name}
      onCancel={onCloseModal}
      buttons={Object.values(adminActions)
        .filter(a => a.visible)
        .map(b => b.btn)}
    >
      <Flex flexDirection="column" gap={20}>
        {selectedItem.selected?.name && (
          <SearchProduct store={selectedItem.selected.name} onSelected={handleSelectedItem} language={"hebrew"} />
        )}
        {drawNotFoundItem()}
        <div>
          {Object.values(adminActions)
            .filter(a => a.visible)
            .map(b => {
              return (
                <Typography.Paragraph key={b.info} secondary size={14}>
                  {b.info}
                </Typography.Paragraph>
              );
            })}
        </div>

        <Flex gap={20} justify="center" responsive={{ sm: { flexDirection: "column" } }}>
          {drawItemCard(selectedItem.adminSuggestion, "adminSuggestion")}
          {drawItemCard(selectedItem.ref, "ref")}
          {drawItemCard(selectedItem.selected, "selected")}
        </Flex>
      </Flex>
    </Modal>
  );
};

export default AdminSelectedModal;
