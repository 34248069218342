import { memo, useEffect, useMemo } from "react";
import { dictButtonsAndActions } from "../../dictionary/dictButtons";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import { StoreEn } from "../../types/types";
import { directionByLanguage } from "../../utils/utils";
import { useCartStore } from "../../Zustand";
import useSelectedItem, { selectedItemOptionsType } from "../../Zustand/stores/useSelectedItem";
import { Flex, Modal, Tab, Tabs } from "../UI";
import SelectedProductInfoTab from "./Tabs/SelectedProductInfoTab";
import SelectedOtherStoresTab from "./Tabs/SelectedOtherStoresTab";
import "./SelectedProduct.css";
import SelectedCompareToFavoriteTab from "./Tabs/SelectedCompareToFavoriteTab";
import ReplaceSelectedBox from "./features/comparison_cart/ReplaceSelectedBox";
import SelectedMainFeatureContent from "./features/main/SelectedMainFeatureContent";
import ItemNotFoundDescription from "./features/comparison_cart/ItemNotFoundDescription/ItemNotFoundDescription";

interface ISelectedProduct {
  store: StoreEn;
}

const SelectedProduct = ({ store }: ISelectedProduct) => {
  const {
    user: { language, isUser },
    onlineStores: { favoriteStore }
  } = useCustomContext();
  const { item, mergeItem, setSelectedProduct, feature, trigger } = useSelectedItem();
  const { cart, addToCart, removeFromCart, updateCartItemStoresData } = useCartStore();
  const refItemCode = useMemo(() => (item ? item[favoriteStore].item_code : null), [item, favoriteStore]);
  const isInCart = useMemo(() => (refItemCode && refItemCode in cart ? true : false), [refItemCode, cart]);

  useEffect(() => {
    if (isInCart) {
      const itemInCart = cart[refItemCode!].product;
      const itemInCartStoresLength = Object.keys(itemInCart).length;
      const currentItemStoresLength = Object.keys(item!).length;
      if (itemInCartStoresLength > currentItemStoresLength) {
        mergeItem(cart[refItemCode!].product);
      } else if (refItemCode && currentItemStoresLength > itemInCartStoresLength) {
        updateCartItemStoresData(refItemCode, item!);
      }
    }
  }, [refItemCode, cart, mergeItem, isInCart, item, updateCartItemStoresData]);

  if (!refItemCode) return null;

  const handleCartAction = () => {
    if (isInCart) {
      removeFromCart(refItemCode);
    } else {
      addToCart(refItemCode, item!, 1);
    }
    setSelectedProduct(null);
  };

  const renderContentByFeature = () => {
    if (!item) return null;

    switch (feature) {
      case "main":
        return (
          <SelectedMainFeatureContent
            imgUrl={item[store].img_url}
            itemName={item[store].item_name}
            itemPrice={item[store].item_price}
            selectedStore={store}
          />
        );
      case "comparison_cart":
        return (
          <ReplaceSelectedBox
            favoriteStore={favoriteStore}
            item={item!}
            language={language}
            refItemCode={refItemCode}
            selectedStore={store}
            trigger={trigger}
          />
        );
      default:
        return null;
    }
  };

  const renderTabsByFeature = () => {
    const tabs: (Tab & {
      features: selectedItemOptionsType["feature"][];
      notAllowedTrigger?: selectedItemOptionsType["trigger"][];
      shouldRender: boolean;
    })[] = [
      {
        content: <SelectedProductInfoTab productData={item?.[store]} />,
        key: "productDetails",
        label: "פרטי מוצר",
        features: ["main", "comparison_cart"],
        notAllowedTrigger: ["cartItem-notFound"],
        shouldRender: true
      },
      {
        content: <SelectedCompareToFavoriteTab favoriteStore={favoriteStore} store={store} item={item!} language={language} />,
        key: "compareToFavorite",
        label: "השוואה",
        features: favoriteStore !== store ? ["comparison_cart"] : [],
        disabled: !isUser,
        notAllowedTrigger: ["cartItem-notFound"],
        shouldRender: true
      },
      {
        content: <ItemNotFoundDescription language={language} />,
        label: "אין התאמה",
        features: ["comparison_cart"],
        shouldRender: trigger === "cartItem-notFound",
        key: "notFoundItem"
      },

      {
        content: <SelectedOtherStoresTab itemCode={refItemCode} />,
        disabled: !isUser,
        key: "similar",
        label: "חנויות אחרות",
        tooltipContent: !isUser ? "פתוח למשתמשים רשומים" : undefined,
        features: ["main"],
        notAllowedTrigger: ["cartItem-notFound"],
        shouldRender: true
      },
      {
        content: "data",
        key: "history",
        label: "היסטורית מחירים",
        disabled: true,
        features: ["comparison_cart", "main"],
        notAllowedTrigger: ["cartItem-notFound"],
        shouldRender: true
      }
    ];

    return tabs
      .filter(t => t.shouldRender && t.features.includes(feature))
      .filter(t => !t.notAllowedTrigger || !t.notAllowedTrigger.includes(trigger));
  };

  return (
    <Modal
      cssClass="selected-product-modal responsive-modal"
      visible={!!item}
      includeCloseBtn
      onCancel={() => setSelectedProduct(null)}
      onOk={handleCartAction}
      okText={dictButtonsAndActions[refItemCode in cart ? "removeFromCart" : "addToCart"][language]}
      cancelText={dictButtonsAndActions[feature === "main" ? "continueShopping" : "close"][language]}
      dir={directionByLanguage(language)}
    >
      <Flex flexDirection="column" align="center" className="p-2" gap={20} style={{ width: "98%", height: "100%" }}>
        {renderContentByFeature()}

        <Tabs contentCss="selected-product-tabs-content scrollable" tabs={renderTabsByFeature()} cssClass="selected-product-tabs-wrapper" />
      </Flex>
    </Modal>
  );
};

export default memo(SelectedProduct);
