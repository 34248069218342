import { useState, FC, Dispatch, SetStateAction, useCallback } from "react";
import { Card, Modal } from "../UI";
import Register from "./Register/Register";
import Login from "./Login/Login";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import Logo from "../Logo/Logo";
import { resolveDirClass } from "../../utils/utils";

type Prop = {
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  type?: AuthType;
  isFullPage?: boolean;
};

export type AuthType = "login" | "register" | "forgetPassword" | "resetPassword";

const Authentication: FC<Prop> = ({ isOpen, setIsOpen, type = "login", isFullPage }) => {
  const [authType, setAuthType] = useState<AuthType>(type);
  const { user } = useCustomContext();

  const handleClose = useCallback(() => {
    setIsOpen && setIsOpen(false);
  }, [setIsOpen]);

  const drawBody = useCallback(() => {
    switch (authType) {
      case "login":
        return <Login isFullPage={isFullPage} setAuthType={setAuthType} handleClose={handleClose} />;
      case "register":
        return <Register setAuthType={setAuthType} handleClose={handleClose} />;
      case "forgetPassword":
        return <ForgetPassword setAuthType={setAuthType} />;
      default:
        return null;
    }
  }, [authType, handleClose, isFullPage]);

  if (!isOpen) return null;

  if (isFullPage) {
    return (
      <Card style={{ maxWidth: 800, minWidth: 330, margin: "10% auto", padding: 20 }} responsive={{ sm: { margin: "auto", padding: 10 } }}>
        {drawBody()}
      </Card>
    );
  }

  return (
    <>
      <Modal
        maskClosable={authType === "login"}
        onCancel={handleClose}
        buttons={[]}
        visible={isOpen}
        cssClass={`modal-auth modal-auth-${authType}`}
        margin={type === "register"}
        dir={resolveDirClass(user.language)}
        title={
          <div style={{ padding: "5px" }}>
            <Logo fontSize={30} />
          </div>
        }
      >
        {drawBody()}
      </Modal>
    </>
  );
};

export default Authentication;
