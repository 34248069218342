import { FC, useMemo } from 'react';
import useCustomContext from '../../../../../hooks/useCustomContext/useCustomContext';
import { useCartStore } from '../../../../../Zustand';
import './DrawMultiCarts.css';
import { StoreEn } from '../../../../../types/types';
import VirtualizedList from '../../../../../components/VirtualizedList/VirtualizedList';
import CartItem from '../../../../../components/Cart/CartItem/CartItem';
import { Button, Flex } from '../../../../../components/UI';
import CartHeader from '../../../../../components/Cart/CartHeader/CartHeader';
import { DrawCartFooterLine } from '../../Ui';
import { cartComparisonExplain } from '../../dict';
import { getMissingItems } from '../../Utils';
import PriceCalculator from '../../../../../models/ItemPriceCalculator/PriceCalculator';
import { dictStores } from '../../../../../dictionary/dictStores';
import { screenSizes } from '../../../../../components/UI/index.hooks';

interface IDrawMultiCartsProps {
  handleSelectStore: (store: StoreEn) => void;
  itemsKeys: string[];
  storesToCompare: StoreEn[];
  onCloseStore: (store: StoreEn) => void;
}

const DrawMultiCarts: FC<IDrawMultiCartsProps> = ({ handleSelectStore, itemsKeys, storesToCompare, onCloseStore }) => {
  const { cart } = useCartStore();
  const {
    onlineStores: { favoriteStore },
    user: { language }
  } = useCustomContext();

  const stores = [favoriteStore, ...storesToCompare];

  const settings = useMemo(() => {
    const isSmallScreen = window.innerWidth < screenSizes.sm;
    const itemsSize = 80;
    const visibleItems = isSmallScreen ? 5 : 7;
    return { gap: 10, itemSize: itemsSize, height: visibleItems * itemsSize, visibleItems: visibleItems };
  }, []);

  const DrawHeader = (
    <Flex gap={settings.gap} justify="space-around">
      {stores.map(s => (
        <div
          key={s}
          className={s === favoriteStore ? 'pseudo-cart-header-wrapper big-cart-item' : 'pseudo-cart-header-wrapper small-cart-item'}
        >
          <CartHeader key={s} store={s} onClose={s !== favoriteStore && storesToCompare.length > 1 ? () => onCloseStore(s) : undefined} />
        </div>
      ))}
    </Flex>
  );

  const DrawFooter = (
    <Flex gap={settings.gap} justify="space-around">
      {stores.map(s => {
        const missingItems = getMissingItems(cart, favoriteStore, s);
        const isOtherData = s !== favoriteStore && missingItems.length > 0;
        const descriptionFirstLine = isOtherData ? `חסרים ${missingItems.length} פריטים` : '';
        const dataTourFooterWrapper = isOtherData ? 'other-cart-footer' : 'favorite-cart-footer';
        return (
          <div key={s} className={s === favoriteStore ? 'big-cart-item' : 'small-cart-item'}>
            <div data-tour={dataTourFooterWrapper} className="cart-comparison_cart_footer">
              <DrawCartFooterLine
                dataTour={isOtherData ? 'cart-missing-items' : ''}
                description={descriptionFirstLine}
                missingItems={missingItems}
                store={s}
              />
              <DrawCartFooterLine
                dataTour={s !== favoriteStore ? 'cart-other-total' : undefined}
                toolTipTitle={'סה"כ עלות לפי x'.replace('x', dictStores[s].hebrew)}
                description={cartComparisonExplain.hebrew.originalTotal}
                price={PriceCalculator.getTotalCartCost(s, cart).toFixed(2)}
              />

              <Button
                dataTour={s !== favoriteStore ? 'open-lunch-cart-modal' : ''}
                size="small"
                variant="contrast"
                onClick={() => handleSelectStore(s)}
                title={` העברת עגלה ל${dictStores[s][language]}`}
              >
                העברת עגלה
              </Button>
            </div>
          </div>
        );
      })}
    </Flex>
  );

  return (
    <Flex dataTour="all-carts" flexDirection="column">
      <VirtualizedList
        height={settings.height} // Height of the visible container
        itemCount={itemsKeys.length}
        itemSize={settings.itemSize}
        visibleItems={settings.visibleItems}
        header={DrawHeader}
        footer={DrawFooter}
      >
        {(index, style) => {
          const itemKey = itemsKeys[index];
          const data = cart[itemKey];

          return (
            <Flex gap={settings.gap} style={style} key={itemKey + index}>
              {stores.map(store => {
                return (
                  <CartItem
                    key={store + data.product[store]?.img_url}
                    productProp={data.product}
                    amount={data.amount}
                    item_code={itemKey}
                    selectedStore={store}
                    featureName={'comparison_cart'}
                    cssClass={store !== favoriteStore ? 'small-cart-item' : 'big-cart-item'}
                  />
                );
              })}
            </Flex>
          );
        }}
      </VirtualizedList>
      {/* {DrawFooter} */}
    </Flex>
  );
};

export default DrawMultiCarts;
