import { useRouteError, isRouteErrorResponse, useLocation, useNavigate } from "react-router-dom";
import "./ErrorPage.css";
import NavBar from "../Navbar/NavBar";
import { Button } from "../UI";
import { CheckVXICon } from "../UI/Icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import Logo from "../Logo/Logo";
import { resolveDirClass } from "../../utils/utils";
import { possibleErrors } from "./helpers/errorPageDict";

// const knownErrorsStatusDict = {
//   "0": {
//     type: "unknown",
//     message: {
//       hebrew: "שגיאה בלתי צפויה - נא צור קשר",
//       english: "Sorry, an unexpected error has occurred",
//       arabic: "عذرًا، حدث خطأ غير متوقع",
//       russian: "Извините, произошла неожиданная ошибка",
//     },
//   },
//   "1": {
//     type: "registration-email exist",
//     message: {
//       hebrew: "אימייל נמצא כבר בשימוש.",
//       english: "Email already exist",
//       arabic: "البريد الإلكتروني موجود بالفعل",
//       russian: "Адрес электронной почты уже существует",
//     },
//   },
//   "2": {
//     type: "registration-verification expired",
//     message: {
//       hebrew: "פג תוקפו של לינק זה , אנא נסו שנית.",
//       english: "Link verification expired, Please try again.",
//       arabic: "انتهت صلاحية التحقق من الرابط، يرجى المحاولة مرة أخرى.",
//       russian: "Срок действия ссылки истек, повторите попытку.",
//     },
//   },
// };

const ErrorPage: React.FC = () => {
  const {
    user: { language },
  } = useCustomContext();

  const location = useLocation();
  const navigate = useNavigate();
  const error = useRouteError();

  const [errorData, setErrorData] = useState({
    status: "",
    message: "",
    description: "",
  });

  const dictGeneral = useMemo(() => {
    return {
      description: {
        hebrew: "אנו מצטערים , הדף שחיפשת אינו זמין. אם לדעתך קיימת בעיה , נשמח לדעת עליה .",
        english:
          "Sorry, the page you're looking for doesn't available. if you think something is broken, report a problem.",
        russian:
          "Извините, страница, которую вы ищете, не существует. Если вы считаете, что что-то не работает, сообщите о проблеме.",
        arabic: "عذرًا، الصفحة التي تبحث عنها غير موجودة. إذا كنت تعتقد أن هناك مشكلة، الرجاء الإبلاغ عنها.",
      },
      btnReport: {
        hebrew: "יצירת קשר",
        english: "Report Problem",
        russian: "Сообщить о проблеме",
        arabic: "الإبلاغ عن مشكلة",
      },
      btnHP: {
        hebrew: "חזרה לדף הבית",
        english: "Return Home",
        russian: "Вернуться на главную",
        arabic: "العودة إلى الصفحة الرئيسية",
      },
    };
  }, []);

  const errorHandler = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const knownErrorMessage = queryParams.get("message");
    let knownErrorStatus = queryParams.get("status");
    let errorMessage: string;

    if (knownErrorMessage) {
      errorMessage =
        possibleErrors[knownErrorMessage as keyof typeof possibleErrors]?.[language] ||
        possibleErrors.unknown[language];
    } else if (isRouteErrorResponse(error)) {
      // error is type `ErrorResponse`
      knownErrorStatus = error.status.toString();
      errorMessage = error.data || error.statusText;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === "string") {
      errorMessage = error;
    } else {
      errorMessage = "0";
    }

    setErrorData({
      status: knownErrorStatus ?? "???",
      message: errorMessage,
      description: knownErrorMessage === "serverDown" ? errorMessage : "",
    });
  }, [error, location, language]);

  useEffect(() => {
    errorHandler();
  }, [errorHandler]);

  return (
    <>
      <NavBar language={language} />
      <div id="error-page" className={`gradient-bg `} style={{ direction: resolveDirClass(language) }}>
        <div className="error-content">
          <div className="error-logo">
            <Logo fontSize={"5rem"} animate />
          </div>
          <div className="error-status gradient-bg">{errorData.status || "???"}</div>
          <div className="error-message">{errorData.description || dictGeneral.description[language]}</div>
          {!errorData.description && (
            <div className={`error-message_original`}>
              {errorData.message}
              <CheckVXICon shape="x" color="red" size="1.4rem" />
            </div>
          )}
          <div className="buttons-ep">
            <Button onClick={() => navigate("/")}>{dictGeneral.btnHP[language]}</Button>
            <Button onClick={() => navigate("/contact")} variant="secondary">
              {dictGeneral.btnReport[language]}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
