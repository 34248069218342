import React from "react";
import "./AnimatedComponent.css";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";

interface AnimatedComponentProps {
  children: React.ReactNode;
  animationClass: string;
  asContainer?: boolean;
}

const AnimatedComponent: React.FC<AnimatedComponentProps> = ({ children, animationClass, asContainer }) => {
  const [targetRef, isInView] = useInfiniteScroll({ threshold: 0.01, rootMargin: "5px" });

  return (
    <div ref={targetRef} className={`animated-component ${isInView ? animationClass : ""}`}>
      {asContainer ? (isInView ? children : null) : children}
    </div>
  );
};

export default AnimatedComponent;
