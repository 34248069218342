import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import "./CartHeader.css";
import { SettingsIcon } from "../../UI/Icons";
import { Drawer, Typography, PopConfirm, CloseBtn } from "../../UI";
import { cartSortingMethods } from "../CartContent/CartContent";
import RadioGroup, { RadioGroupOption } from "../../UI/RadioGroup/RadioGroup";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { dictStores } from "../../../dictionary/dictStores";
import { StoreEn } from "../../../types/types";
import { useCartStore } from "../../../Zustand";

type Props = {
  setSortingMethod?: Dispatch<SetStateAction<cartSortingMethods>>;
  sortingMethod?: cartSortingMethods;
  store: StoreEn;
  onClose?: () => void;
};

const CartHeader: FC<Props> = ({ setSortingMethod, sortingMethod, store, onClose }) => {
  const {
    user: { language },
  } = useCustomContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { clearCart, cart } = useCartStore();

  const handleCleanCart = () => {
    clearCart();
    setIsDrawerOpen(false);
  };

  const sortOptions: RadioGroupOption[] = useMemo(() => {
    return [
      { label: "סדר הוספה לעגלה", value: "order" },
      { label: "אותיות", value: "abc" },
      { label: "לפי קטגוריות", value: "categories" },
    ];
  }, []);

  const cartTitleString = () => {
    return <Typography.Text>{dictStores[store][language]}</Typography.Text>;
  };

  if (!onClose) return <div className="cart-header">{cartTitleString()} </div>;

  if (!setSortingMethod || !Object.keys(cart).length) {
    return (
      <div className="cart-header">
        <CloseBtn
          size={"1.3em"}
          cssClass="cart-header-cls-btn"
          position={{ left: 6, top: 6 }}
          strong
          onClick={onClose}
        />
        <span>{cartTitleString()}</span>
      </div>
    );
  }

  const HandleSortingMethod = (value: string) => {
    if (!setSortingMethod) return null;
    switch (value) {
      case "order":
        setSortingMethod("order");
        break;
      case "abc":
        setSortingMethod("abc");
        break;
      case "categories":
        setSortingMethod("categories");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="cart-header">
        <div onClick={() => setIsDrawerOpen((p) => !p)}>
          <SettingsIcon size="18px" clickable />
        </div>
        {cartTitleString()}
      </div>
      <Drawer
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        placement="top"
        getContainer
        closable
        headerStyle={{ backgroundColor: "var(--primary-color)", color: "var(--white)" }}
        title="אפשרויות נוספות"
      >
        <div style={{ padding: "5px 15px" }}>
          <Typography.Title level={6}> מיון פריטים בעגלה לפי:</Typography.Title>
          <RadioGroup
            style={{ display: "flex", flexDirection: "column" }}
            options={sortOptions}
            name="sortingCart"
            onChange={HandleSortingMethod}
            defaultValue={sortingMethod}
          />
        </div>

        <div style={{ margin: "10px" }}>
          <PopConfirm
            description="האם אתם בטוחים שברצונכם לרוקן את העגלה?"
            onConfirm={() => handleCleanCart()}
            onCancel={() => console.log("x")}
            title="ריקון עגלה"
            closable={false}
            btnProps={{ size: "medium", variant: "danger", style: { width: "100%" } }}
            placement="bottom"
            offset={-10}
          >
            ריקון עגלה
          </PopConfirm>
        </div>
      </Drawer>
    </>
  );
};

export default CartHeader;
