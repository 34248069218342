import { CSSProperties, useCallback, useEffect, useState } from "react";
import serverAPI from "../../../api/server";
import CategoryPricesChart, { CategoryPricesType } from "./CategoryPricesChart/CategoryPricesChart";
import { CarouselFull, Modal, SkeletonCard, Typography } from "../../../components/UI";
import { Language } from "../../../types/types";
import { InfoIcon } from "../../../components/UI/Icons";
import PriceChartInfo from "./PriceChartInfo/PriceChartInfo";
import { screenSizes } from "../../../components/UI/index.hooks";
import CategoriesMap from "../../../assets/Data/categories";

const style: CSSProperties = {
  width: "100%"
};

interface ICategoriesPricesChartsCarouselProps {
  title: string;
  showIcons: boolean;
  language: Language;
}

const CategoriesPricesChartsCarousel = ({ title, showIcons, language }: ICategoriesPricesChartsCarouselProps) => {
  const [chartsData, setChartsData] = useState<CategoryPricesType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await serverAPI("products/prices/all", "GET");

      if (res?.data && res.statusCode === 200) setChartsData(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // TODO: FIX THIS API CALL OR REMOVE IT FROM BE AND FE
  useEffect(() => {
    1 > 2 && fetchData();
  }, [fetchData]);

  const drawCharts = () => {
    return chartsData
      .filter(cat => cat.count > 0)
      .map((category, idx) => {
        const catObj = CategoriesMap.find(c => c.id === category.category_value);
        category.category_label = catObj ? catObj[language] : "???";
        return <CategoryPricesChart showIcons={showIcons} key={idx} data={category} />;
      });
  };

  const drawChartSkeletonLoader = useCallback(() => {
    const arr = [1, 2, 3, 4];
    return (
      <div style={{ display: "flex", gap: 20 }}>
        {arr.map(i => {
          return <ChartSkeleton key={i} />;
        })}
      </div>
    );
  }, []);

  return (
    <>
      <div className="flex-align">
        <InfoIcon color="var(--primary-color)" size="20px" clickable onClick={() => setInfoModalOpen(true)} />

        <div className="transparent">x</div>
        <Typography.Text size={20} type="secondary">
          {title}
        </Typography.Text>
      </div>

      <div style={style}>
        {isLoading ? (
          drawChartSkeletonLoader()
        ) : (
          <CarouselFull infinite slideToShow={window.innerWidth < screenSizes.sm ? 1 : 7} gap={5}>
            {drawCharts()}
          </CarouselFull>
        )}
      </div>
      {infoModalOpen && (
        <Modal
          buttons={[]}
          title="השוואת קטגוריות"
          onCancel={() => setInfoModalOpen(false)}
          visible={infoModalOpen}
          dir="rtl"
          style={{ maxWidth: 500 }}
          // width={350}
          language={language}
        >
          <PriceChartInfo language={language} />
        </Modal>
      )}
    </>
  );
};

export default CategoriesPricesChartsCarousel;

// #region skeleton

const ChartSkeleton = () => {
  return (
    <SkeletonCard
      animatedElements={[
        { left: "35%", width: "35%", height: "10%", top: "5%" },
        { left: "25%", width: "55%", height: "7%", top: "20%" },
        { left: "5%", width: "50%", height: "50%", top: "35%" },
        { left: "65%", width: "30%", height: "50%", top: "35%", type: "circle" }
      ]}
      height="250px"
      width="33%"
    />
  );
};

//#endregion
