import { FC } from 'react';
import { Divider } from '../../../UI';
import { StoreEn } from '../../../../types/types';
import CartItem from '../CartItem';
import { comparisonIndicators } from '../../../../pages/CartComparisonPage/Helpers/Ui';

interface IProps {
  favoriteItemName: string;
  store: StoreEn;
  onClick: () => void;
}

const NotFoundItem: FC<IProps> = ({ store, favoriteItemName, onClick }) => {
  const DrawInitState = () => {
    return CartItem.DrawProduct({
      boldString: 'לא מצאנו התאמה',
      imgNode: <div className="cart-item_not_found_in_cart_img"></div>,
      weakNode: <span>{favoriteItemName}</span>,
      onClick,
      store: store
    });
  };

  return (
    <div data-tour="not-found-item" className="cart-item-card small-cart-item">
      <div className="cart-product-wrapper ">
        {DrawInitState()}
        {comparisonIndicators.notFound.icon}
      </div>
      <Divider margin="0" />
    </div>
  );
};

export default NotFoundItem;
