import { Dispatch, FC, useCallback, useMemo, useState } from "react";
import { dictAuth } from "../../dictionary";
import { EmailIcon, PasswordHideIcon, PasswordShowIcon, PhoneIcon, UserIcon } from "../../../UI/Icons";
import { Language } from "../../../../types/types";
import { ActionAuth, AuthenticationFormState } from "../../AuthReducer/AuthReducer";
import PasswordStrengthMeter from "../PasswordStrengthMeter/PasswordStrengthMeter";
import { isValidIsraelPhoneNumber } from "../../../../utils/utils";
import { Flex, InputUI } from "../../../UI";

type Props = {
  language: Language;
  dispatch: Dispatch<ActionAuth>;
  formState: AuthenticationFormState;
  source?: "auth" | "privacySettings";
};

const PersonalData: FC<Props> = ({ source = "auth", language, dispatch, formState }) => {
  const [togglePasswordVisible, setTogglePasswordVisible] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const onPasswordIconClick = () => {
    setTogglePasswordVisible(p => !p);
  };

  const passwordIcon = useMemo(() => {
    return togglePasswordVisible ? (
      <PasswordShowIcon clickable onClick={onPasswordIconClick} />
    ) : (
      <PasswordHideIcon clickable onClick={onPasswordIconClick} />
    );
  }, [togglePasswordVisible]);

  // #region Validators

  const validatePhoneNumber = useCallback(
    (v: string) => {
      if (!isValidIsraelPhoneNumber(v)) return [{ isOk: false, onOkMessage: "", onFalseMessage: dictAuth.registerPhoneInvalid[language] }];
      return true;
    },
    [language]
  );

  const validatePassword = useCallback(
    (v: string) => {
      const isLength = v.length >= 6;
      const hasLowercase = /[a-z]/.test(v);
      const hasCapcase = /[A-Z]/.test(v);

      if (isLength && hasLowercase && hasCapcase) return true;

      const dict = {
        length: [
          {
            // Message when the validation fails
            hebrew: "הסיסמה חייבת להכיל לפחות 6 תווים.",
            english: "The password must contain at least 6 characters.",
            arabic: "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل.",
            russian: "Пароль должен содержать не менее 6 символов."
          },
          {
            // Message when the validation passes
            hebrew: "הסיסמה מכילה לפחות 6 תווים.",
            english: "The password contains at least 6 characters.",
            arabic: "كلمة المرور تحتوي على 6 أحرف على الأقل.",
            russian: "Пароль содержит не менее 6 символов."
          }
        ],
        hasLowercase: [
          {
            // Message when the validation fails
            hebrew: "הסיסמה חייבת להכיל לפחות אות קטנה אחת באנגלית.",
            english: "The password must contain at least one lowercase letter.",
            arabic: "يجب أن تحتوي كلمة المرور على حرف صغير واحد على الأقل.",
            russian: "Пароль должен содержать хотя бы одну строчную букву."
          },
          {
            // Message when the validation passes
            hebrew: "הסיסמה מכילה לפחות אות קטנה אחת באנגלית.",
            english: "The password contains at least one lowercase letter.",
            arabic: "كلمة المرور تحتوي على حرف صغير واحد على الأقل.",
            russian: "Пароль содержит хотя бы одну строчную букву."
          }
        ],
        hasCapcase: [
          {
            // Message when the validation fails
            hebrew: "הסיסמה חייבת להכיל לפחות אות גדולה אחת באנגלית.",
            english: "The password must contain at least one uppercase letter.",
            arabic: "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل.",
            russian: "Пароль должен содержать хотя бы одну заглавную букву."
          },
          {
            // Message when the validation passes
            hebrew: "הסיסמה מכילה לפחות אות גדולה אחת באנגלית.",
            english: "The password contains at least one uppercase letter.",
            arabic: "كلمة المرور تحتوي على حرف كبير واحد على الأقل.",
            russian: "Пароль содержит хотя бы одну заглавную букву."
          }
        ]
      };

      return [
        {
          isOk: isLength,
          onFalseMessage: dict.length[0][language],
          onOkMessage: dict.length[1][language]
        },
        {
          isOk: hasLowercase,
          onFalseMessage: dict.hasLowercase[0][language],
          onOkMessage: dict.hasLowercase[1][language]
        },
        { isOk: hasCapcase, onFalseMessage: dict.hasCapcase[0][language], onOkMessage: dict.hasCapcase[1][language] }
      ];
    },
    [language]
  );

  const validateConfirmPassword = useCallback(
    (v: string) => {
      if (v !== formState.password && v.length >= formState.password.length)
        return [{ isOk: false, onFalseMessage: dictAuth.passwordNotMatch[language], onOkMessage: "" }];
      return true;
    },
    [formState.password, language]
  );

  // #endregion validators

  return (
    <Flex
      flexDirection="column"
      cssClass="scrollable"
      gap={source === "auth" ? 20 : 30}
      justify="space-between"
      responsive={{ sm: { maxHeight: "65vh", overflowX: "hidden", overflowY: "auto", paddingTop: 18 } }}
    >
      <InputUI
        id="email"
        name="SET_EMAIL"
        required
        type="email"
        label={dictAuth.email[language]}
        value={formState.email}
        onChange={handleInputChange}
        icon={<EmailIcon />}
      />

      <InputUI
        id="full_name"
        name="SET_FULL_NAME"
        required
        label={dictAuth.fullName[language]}
        value={formState.full_name}
        onChange={handleInputChange}
        icon={<UserIcon size={"1em"} color="currentColor" />}
      />

      <InputUI
        id="phone_number"
        name="SET_PHONE"
        type="number"
        label={dictAuth.phoneNumber[language]}
        value={formState.phone_number}
        onChange={handleInputChange}
        icon={<PhoneIcon />}
        validate={validatePhoneNumber}
      />

      {source === "auth" && (
        <>
          <InputUI
            id="password"
            name="SET_PASSWORD"
            required
            type={togglePasswordVisible ? "text" : "password"}
            label={dictAuth.password[language]}
            value={formState.password}
            onChange={handleInputChange}
            icon={passwordIcon}
            validate={validatePassword}
          />
          <InputUI
            id="passwordConfirm"
            name="SET_CONFIRM_PASSWORD"
            required
            type={togglePasswordVisible ? "text" : "password"}
            label={dictAuth.confirmPassword[language]}
            value={formState.confirmPassword}
            onChange={handleInputChange}
            icon={passwordIcon}
            validate={validateConfirmPassword}
          />
          <PasswordStrengthMeter password={formState.password} />
        </>
      )}
    </Flex>
  );
};

export default PersonalData;
