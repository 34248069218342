import React, { FC } from "react";
import { iconClassName, IconCustom } from "../types";

type IconShapeProps = {
  isCircle?: boolean;
  shape: "x" | "v";
  outlined?: boolean;
};

type DynamicIconProps = IconCustom & IconShapeProps;

const CheckVXICon: FC<DynamicIconProps> = ({
  outlined,
  isCircle = true,
  shape = "v",
  cssClass,
  clickable,
  color = "currentColor",
  size = "1.5em",
  onClick
}) => {
  const paths = {
    v: "M8 12.5L10.5 15L16 9.5",
    x: "M15 9L9 15M9 9L15 15"
  };
  return (
    <svg
      onClick={onClick}
      width={size}
      height={size}
      className={iconClassName(clickable, cssClass)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g id="check-icon">
        {isCircle && <circle cx="12" cy="12" r="10" stroke={color} fill={outlined ? "none" : color} strokeWidth="2" />}
        <path
          d={paths[shape]}
          fill={"none"}
          stroke={outlined || !isCircle ? color : "white"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CheckVXICon;
