// type getProductsEP = `/products/:category` | "products/search/store/category" | any;

import { goToErrorPage } from "../components/ErrorPage/helpers/errorPageHelper";
import csrfToken from "../models/Tokens/CsrfToken";
import { CookieName } from "../types/types";
import { urlBE } from "../utils/constants";
import { parseCookies, serializeParams } from "../utils/utils";
import { appEndPoints } from "./endPoints.types";

type ServerResponse<T> = { data: T; description?: string; statusCode: number };

// const serverAPI = async (url: string, body: any, description?: string) => {
const serverAPI = async <T extends keyof appEndPoints>(
  url: T,
  body: appEndPoints[T]["body"],
  options?: { description?: string; params?: appEndPoints[T]["params"] }
): Promise<ServerResponse<appEndPoints[T]["response"]>> => {
  let BODY, method;
  if (body === "GET") {
    method = "GET";
    BODY = undefined;
  } else {
    method = "POST";
    BODY = JSON.stringify(body);
  }
  try {
    const authToken = parseCookies(CookieName.AuthUserData);
    const headers: HeadersInit = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken || ""}`
    };

    // CSRF TOKEN
    const csrfTokenStr = csrfToken.getValidatedToken();
    if (csrfTokenStr) {
      headers["X-CSRF"] = csrfTokenStr;
    } else if (url !== "client/init") {
      headers["X-CSRF"] = await csrfToken.initOrUpdateCsrfToken(authToken);
    }

    let fullUrl = urlBE + url;

    if (options?.params) {
      fullUrl += `?${serializeParams(options.params)}`;
    }

    const response = await fetch(fullUrl, {
      method,
      credentials: "include",
      headers,
      body: BODY
    });

    if (!response.ok) {
      if (response.status === 429) {
        window.alert("השרת עמוס... נא להמתין מספר דקות");
      }
      throw response;
    }

    const jsonData = await response.json();

    return { data: jsonData.data, description: options?.description, statusCode: response.status };
  } catch (error: any) {
    console.log(JSON.stringify(error));

    if (error.message === "Failed to fetch") {
      goToErrorPage({ message: "serverDown", status: 500, useDom: true });
    }

    return {
      data: error.message,
      description: options?.description,
      statusCode: error.status || error.statusCode || 500
    };
  }
};

export default serverAPI;
