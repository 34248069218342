import { Dispatch, FormEvent, SetStateAction, useCallback, useMemo } from "react";
import { Button, Checkbox, Flex, PopConfirm, SelectSearchOptionType, SelectSearchUI } from "../../../../UI";
import OnlineStoresDict from "../../../../../utils/onlineStoresDict";
import CategoriesMap, { SubCategory } from "../../../../../assets/Data/categories";
import { formFetchDataType } from "../AdminMatchingProducts";
import serverAPI from "../../../../../api/server";

interface IMatchingForm {
  formData: formFetchDataType;
  setFormData: Dispatch<SetStateAction<formFetchDataType>>;
  toggleLoading: Dispatch<SetStateAction<boolean>>;
}

const MatchingForm = ({ formData, setFormData, toggleLoading }: IMatchingForm) => {
  const onChange = useCallback(
    (name: keyof formFetchDataType, option: SelectSearchOptionType[]) => {
      setFormData(p => {
        return { ...p, [name]: option };
      });
    },
    [setFormData]
  );

  function onReset() {
    setFormData({
      category: [],
      favoriteStore: [],
      otherStores: [],
      subCategory: [],
      products: {},
      prevFetchOptions: "",
      filterSameItemCode: true,
      filterNameSimilarity: false
    });
  }

  const generateFetchPayload = useCallback(() => {
    const payload = {
      favoriteStoreId: formData.favoriteStore[0].data.id as number,
      otherStores: formData.otherStores.map((i: any) => ({ name: i.data.name, id: i.data.id })),
      subCategoryId: parseInt(formData.subCategory[0].data.id)
    };

    // Need to fetch data from the favorite also
    payload.otherStores.push({ id: payload.favoriteStoreId, name: formData.favoriteStore[0].data.name });
    return payload;
  }, [formData]);

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      toggleLoading(true);
      const payload = generateFetchPayload();

      if (JSON.stringify(payload) === formData.prevFetchOptions) {
        throw new Error("Already fetched");
      }

      const { data } = await serverAPI("cart/compare-admin", payload);

      setFormData(p => ({ ...p, products: data, prevFetchOptions: JSON.stringify(payload) }));
      console.log("Parsed Data:", payload);
    } catch (error) {
      console.error(error);
    } finally {
      toggleLoading(false);
    }
  }

  const SelectRefStore = ({
    flex,
    placeholder,
    name,
    isMulti
  }: {
    flex?: number;
    placeholder: string;
    isMulti?: boolean;
    name: Exclude<keyof formFetchDataType, "products" | "prevFetchOptions" | "filterSameItemCode" | "filterNameSimilarity">;
  }) => {
    const options = useMemo(() => {
      return OnlineStoresDict.getStores("hebrew").map(s => {
        return { label: s.label, value: s.id, data: { name: s.name, id: parseInt(s.id) } };
      });
    }, []);

    const storesToRender = options.filter(s => {
      if (name === "favoriteStore" && formData.otherStores.find(other => other.value === s.value)) {
        return false;
      }

      if (name === "otherStores" && formData.favoriteStore.find(fav => fav.value === s.value)) {
        return false;
      }

      return true;
    });

    return (
      <SelectSearchUI
        placeholder={placeholder}
        options={storesToRender}
        label={{ text: placeholder }}
        name={name}
        style={{ flex }}
        isMulti={isMulti}
        onChange={s => onChange(name, s)}
        values={formData[name]}
      />
    );
  };

  const SelectCategoriesForm = () => {
    const subCategories = useMemo(() => {
      if (!formData.category.length) return [];

      return formData.category[0].data.map((sub: SubCategory) => {
        return { value: sub.id.toString(), label: sub.hebrew, data: { id: sub.id } };
      });
    }, []);

    return (
      <>
        <SelectSearchUI
          label={{ text: "בחירת קטגוריה" }}
          placeholder="בחירת קטגוריה"
          name="category"
          style={{ flex: 0.5 }}
          options={CategoriesMap.map(c => ({ label: c.hebrew, value: c.id.toString(), data: c.sub_categories }))}
          onChange={s => onChange("category", s)}
          disabled={formData.subCategory.length !== 0}
          values={formData.category}
        />
        <SelectSearchUI
          style={{ flex: 0.5 }}
          label={{ text: "בחירת תת קטגוריה" }}
          placeholder="בחירת תת קטגוריה"
          name="subCategory"
          options={subCategories}
          disabled={subCategories.length === 0}
          values={formData.subCategory}
          onChange={s => onChange("subCategory", s)}
        />
      </>
    );
  };

  const isValidFetchData = useMemo(() => {
    if (!formData.favoriteStore.length) return false;
    else if (!formData.otherStores.length) return false;
    else if (!formData.subCategory.length) return false;

    const payload = generateFetchPayload();
    if (JSON.stringify(payload) === formData.prevFetchOptions) return false;

    return true;
  }, [formData, generateFetchPayload]);

  const isFormDataEmpty = (): boolean => {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key as keyof formFetchDataType];

        if (Array.isArray(value)) {
          if (value.length > 0) {
            return false; // Array is not empty
          }
        } else if (typeof value === "object" && value !== null) {
          if (Object.keys(value).length > 0) {
            return false; // Object is not empty
          }
        }
        // Add more checks if you have other data types in your form data
      }
    }
    return true; // All properties are empty
  };

  const toggleFilterType = (type: "autoCode" | "nameSimilarity") => {
    if (type === "autoCode") {
      setFormData(prev => ({ ...prev, filterSameItemCode: !prev.filterSameItemCode }));
    } else {
      setFormData(prev => ({ ...prev, filterNameSimilarity: !prev.filterNameSimilarity }));
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Flex justify="flex-start" gap={15} wrap="wrap" align="center">
        <SelectRefStore name={"favoriteStore"} placeholder="בחירת חנות ראשית" />
        <SelectRefStore name={"otherStores"} flex={1} isMulti placeholder="חנויות להשוואה" />
        <SelectCategoriesForm />
        <Checkbox label="סנן קוד זהה" onChange={() => toggleFilterType("autoCode")} checked={formData.filterSameItemCode} />
        <Checkbox label="סנן התאמה לפי שם" onChange={() => toggleFilterType("nameSimilarity")} checked={formData.filterNameSimilarity} />
      </Flex>
      <Flex gap={50} justify="space-between" responsive={{ sm: { flexDirection: "column" } }}>
        <Button variant="primary" htmlType="submit" disabled={!isValidFetchData}>
          ייבא נתונים
        </Button>
        <Button variant="contrast">שמירת שינויים</Button>
        <Button variant="info">צפייה בשינויים</Button>
        <PopConfirm
          description="פעולה זו תאפס את הבחירות , תנקה את הפריטים ותרוקן את התוכן הכללי"
          onConfirm={onReset}
          title="איפוס מידע"
          placement="bottom"
          popStyle={{ marginLeft: 20 }}
          disabled={isFormDataEmpty()}
        >
          איפוס
        </PopConfirm>
      </Flex>
    </form>
  );
};

export default MatchingForm;
