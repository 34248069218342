import { memo, useCallback, useEffect, useRef, useState } from "react";
import serverAPI from "../../../api/server";
import OnlineStoresDict from "../../../utils/onlineStoresDict";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { ProductData, StoreEn } from "../../../types/types";
import { Card, Flex, ImageComponent, SpinnerA, Typography } from "../../UI";
import useSelectedItem from "../../../Zustand/stores/useSelectedItem";
import { dictStores } from "../../../dictionary/dictStores";
import { resolveImgUrl } from "../../../utils/utils";

const SelectedOtherStoresTab = ({ itemCode }: { itemCode: string }) => {
  const {
    user: { language },
    onlineStores: { favoriteStore, otherStores }
  } = useCustomContext();

  const { item, mergeItem } = useSelectedItem();

  const [isLoading, setIsLoading] = useState(false);
  const isItemFull = useRef(Object.keys(item!).length > 1);

  const fetchItemsFromOtherStores = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await serverAPI("cart/compare-items", {
        favoriteStoreId: OnlineStoresDict.getOnlineStoreIdFromName(favoriteStore),
        otherStores: OnlineStoresDict.convertOnlineStoresToArrOfObj(otherStores),
        selectedItems: [itemCode]
      });

      mergeItem(response.data[itemCode]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [favoriteStore, itemCode, otherStores, mergeItem]);

  useEffect(() => {
    if (!isItemFull.current) {
      fetchItemsFromOtherStores();
    }
  }, [fetchItemsFromOtherStores]);

  if (isLoading) {
    return <SpinnerA size={50} />;
  }

  if (!itemCode) return null;

  const drawCards = () => {
    const card = (store: StoreEn, storeItem: ProductData | null) => {
      if (!storeItem) {
        return null;
      }

      return (
        <Card>
          <Flex flexDirection="column" align="center" gap={3} style={{ height: 160, width: 140 }}>
            <Typography.Text weight={500}>{dictStores[store][language]}</Typography.Text>
            <ImageComponent src={resolveImgUrl(store, storeItem.img_url)} alt={storeItem.item_name} height={65} width={65} />

            <Typography.Text size={"var(--font-size-small)"} style={{ height: 60 }} align="center" weight={700}>
              {storeItem.item_name}
            </Typography.Text>
            <Typography.Text size={"var(--font-size-small)"} color="var(--primary-color)">
              <span>&#8362;</span>
              {storeItem.item_price}
            </Typography.Text>
          </Flex>
        </Card>
      );
    };

    return Object.keys(item!)
      .filter(s => s !== favoriteStore)
      .map(store => {
        const storeProduct = item![store as StoreEn];

        return card(store as StoreEn, storeProduct);
      });
  };

  return (
    <Flex wrap="wrap" gap={5}>
      {drawCards()}
    </Flex>
  );
};

export default memo(SelectedOtherStoresTab);
