import { FC, useCallback, useMemo } from 'react';
import './CartItem.css';
import { Divider, ImageComponent } from '../../UI';
import { PlusOrMinusIcon } from '../../UI/Icons';
import { ProductDataType, StoreEn } from '../../../types/types';
import NotFoundItem from './NotFoundItem/NotFoundItem';
import useCustomContext from '../../../hooks/useCustomContext/useCustomContext';
import PriceCalculator from '../../../models/ItemPriceCalculator/PriceCalculator';
import { useCartStore } from '../../../Zustand';
import { drawMatchScoringIcon } from '../../../utils/utils';
import useSelectedItem, { selectedItemOptionsType } from '../../../Zustand/stores/useSelectedItem';

type Props = {
  productProp: ProductDataType;
  amount: number;
  item_code: string;
  selectedStore: StoreEn;
  featureName?: selectedItemOptionsType['feature'];
  cssClass?: string;
};

// * Cart Item references
// * 1. In Cart
// * 2. In Cart Comparison Page

type CartItemComponent = FC<Props> & {
  DrawProduct: (args: DrawProductArgs) => JSX.Element;
  DrawItemCartActions: (args: DrawItemCartActions) => JSX.Element;
};

const CartItem: CartItemComponent = ({ productProp, amount, item_code, selectedStore, featureName = 'main', cssClass }) => {
  const {
    onlineStores: { favoriteStore }
  } = useCustomContext();

  const { cart, updateCartItemAmount } = useCartStore();
  const { setSelectedProduct } = useSelectedItem();

  const handleChangeAmount = (type: 'minus' | 'plus') => {
    const amount = type === 'minus' ? -1 : 1;
    updateCartItemAmount(item_code, amount);
  };

  const onItemClick = useCallback(
    (trigger: selectedItemOptionsType['trigger']) => {
      setSelectedProduct({
        item: productProp,
        selectedStoreItem: selectedStore,
        feature: featureName,
        trigger: trigger
      });
    },
    [featureName, productProp, selectedStore, setSelectedProduct]
  );

  const drawProductInCart = useCallback(() => {
    if (!productProp[selectedStore]) return null;
    return CartItem.DrawProduct({
      boldString: productProp[selectedStore].item_name,
      onClick: () => {
        onItemClick('cartItem');
      },
      store: selectedStore,
      imgNode: (
        <ImageComponent
          loading="lazy"
          src={productProp[selectedStore].img_url}
          alt={'cart item ' + productProp[selectedStore].item_name}
          onErrorSize="small"
          width={45}
          height={45}
        />
      ),
      weakNode: (
        <>
          <span>{productProp[selectedStore].item_price}</span>
          <span>&#8362;</span>
          <span> </span>
          <span>ל - </span>
          <span> </span>
          <span>{productProp[selectedStore].unit_qty}</span>
        </>
      )
    });
  }, [productProp, onItemClick, selectedStore]);

  const itemPrice = useMemo(() => {
    return PriceCalculator.getItemPriceToString(selectedStore, { product: productProp, amount }, cart);
  }, [amount, productProp, cart, selectedStore]);

  if (!productProp[selectedStore] && !cart[item_code].product[selectedStore]) {
    return (
      <NotFoundItem
        onClick={() => {
          onItemClick('cartItem-notFound');
        }}
        favoriteItemName={productProp[favoriteStore].item_name}
        store={selectedStore}
      />
    );
  }

  return (
    <div className={`cart-item-card ${cssClass ?? ''}`}>
      <div className="cart-product-wrapper">
        {drawProductInCart()}
        {featureName === 'comparison_cart' && selectedStore !== favoriteStore
          ? drawMatchScoringIcon(productProp[selectedStore]?.source)
          : CartItem.DrawItemCartActions({
              handleAction: handleChangeAmount,
              amount,
              color: 'var(--primary-color)',
              calculatedPrice: itemPrice
            })}
      </div>
      <Divider margin="0" />
    </div>
  );
};

// Cart Item helpers

type DrawProductArgs = {
  store: StoreEn;
  onClick: () => void;
  boldString: string;
  weakNode: React.ReactNode;
  imgNode: React.ReactNode;
};

CartItem.DrawProduct = ({ store, onClick, boldString, weakNode, imgNode }: DrawProductArgs) => {
  return (
    <div className="cart-item-details_wrapper" key={store} onClick={onClick}>
      <div className="cart-item-details">
        <div>
          <b>{boldString}</b>
        </div>
        <div className="weak">{weakNode}</div>
      </div>
      <div className="cart-item-img">{imgNode}</div>
    </div>
  );
};

type DrawItemCartActions = {
  handleAction?: (action: 'plus' | 'minus') => void;
  color: string;
  calculatedPrice: string | number;
  amount: number;
};

CartItem.DrawItemCartActions = ({ amount, handleAction, color, calculatedPrice }: DrawItemCartActions) => {
  return (
    <div className="cart-item-buttons-wrapper">
      <div className="cart-item-buttons">
        <div className="cart-item-amount-btn" onClick={() => handleAction && handleAction('plus')}>
          <PlusOrMinusIcon color={color} sign="plus" size={'1rem'} clickable={!!handleAction} />
        </div>
        <div className="item-amount-unit-wrapper">{amount}</div>
        <div className="cart-item-amount-btn" onClick={() => handleAction && handleAction('minus')}>
          <PlusOrMinusIcon color={color} sign="minus" size={'1rem'} clickable={!!handleAction} />
        </div>
      </div>
      <div className="cart-item-price">
        <span>{calculatedPrice}</span>
        <span>&#8362;</span>
      </div>
    </div>
  );
};

export default CartItem;
