import { FC, useMemo } from "react";
import "./MobileDepartmentsNav.css";
import { IconCustom } from "../../components/UI/Icons/types";
import { Link } from "react-router-dom";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import { Accordion, AccordionItem, Flex } from "../../components/UI";
import CategoriesMap from "../../assets/Data/categories";

const MobileDepartmentsNav = () => {
  const {
    user: { language }
  } = useCustomContext();

  const generateItems = useMemo(() => {
    const items: AccordionItem[] = [];

    CategoriesMap.forEach(categoryObj => {
      const title = categoryObj[language];
      const Icon: FC<IconCustom> = categoryObj.icon;

      const label = (
        <Flex gap={25} style={{ width: "100%" }}>
          <span>
            <Icon hovered />
          </span>
          <span>{title}</span>
        </Flex>
      );

      const children = categoryObj.sub_categories.map(sub => {
        return (
          <Link
            style={{
              boxShadow: "var(--box-shadow)",
              width: "100%",
              padding: "15px"
            }}
            to={`/products/${sub.id}`}
          >
            {sub[language]}
          </Link>
        );
      });

      items.push({ key: categoryObj.english, label, children });
    });

    return items;
  }, [language]);

  return (
    <div className="mobile-departments-container">
      <Accordion items={generateItems} />
    </div>
  );
};

export default MobileDepartmentsNav;
