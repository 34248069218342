import { Language, ProductData, StoreEn } from "../../types/types";
import { findCategoryObject, resolveImgUrl } from "../../utils/utils";
import { Flex, ImageComponent, Typography } from "../UI";
import Promotion from "../Promotion/Promotion";
import "./ProductSearchItemCard.css";

export const categoryBreadCrumb = (product: ProductData, language: Language) => {
  const categoryData = findCategoryObject(product.category);
  const subcategoryData = categoryData.sub_categories.find(i => i.id === product.sub_category);
  const segment = product.segment;
  return (
    <>
      <span>{categoryData[language]}</span>
      {subcategoryData && <span>/{subcategoryData[language]}</span>}
      {segment && <span>/{segment}</span>}
    </>
  );
};

const ProductSearchItemCard = ({ store, product, language }: { store: StoreEn; product: ProductData; language: Language }) => {
  const textSize = "calc(--font-size-base - 1px)";
  const smallerTextSize = "calc(--font-size-base - 3px)";

  return (
    <Flex
      cssClass="ProductSearchItemCard-wrapper pr-3"
      gap={15}
      responsive={{ sm: { minWidth: "80vw", paddingRight: "3px" } }}
      align="center"
    >
      {/* img */}
      <div className="p-1">
        <ImageComponent width={90} height={90} src={resolveImgUrl(store, product.img_url)} alt={product.item_name} />
      </div>
      <Flex flexDirection="column" gap={10} className="px-3" style={{ width: "100%" }}>
        {/* name  */}
        <Flex flexDirection="column" justify="space-between">
          <Typography.Text secondary size={smallerTextSize} weight={400} className="responsive-hidden">
            {categoryBreadCrumb(product, language)}
          </Typography.Text>
          <Typography.Text weight={600} size={textSize}>
            {product.item_name}
          </Typography.Text>
        </Flex>

        {/* promotion */}
        {product.promotions?.length && (
          <Flex gap={6} responsive={{ sm: { flexDirection: "column" } }}>
            {product.promotions.map((promo, idx) => (
              <Promotion key={idx} promotion={promo} unit={product.unit_of_measure ?? ""} />
            ))}
          </Flex>
        )}

        {/* price info */}
        <Flex gap={3}>
          <Typography.Text size={textSize} weight={600}>
            <span>&#8362;</span>
            <span>{product.item_price}</span>
          </Typography.Text>
          <Typography.Text size={textSize} weight={400} secondary>
            <span>&#8362;</span>
            <span>{product.unit_of_measure_price}</span>

            <span> /</span>
            <span>{product.unit_of_measure}</span>
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProductSearchItemCard;
