export const passwordStrengthValue = 6;

// ! Must be same as BE constant/ ports obj
const ports = {
  https: 4000, //443 is default but it requires elevated permissions - need to learn
  http: 8000, // 80 same on https
  frontEnd: 3000
};

// const APIipBE = process.env.NODE_ENV === "development" ? "localhost" : "51.17.140.176";
// const APIipBE = process.env.NODE_ENV === "development" ? "localhost" : "api.tozilu.net";
const APIipFE = process.env.NODE_ENV === "development" ? "localhost" : "tozilu.net"; //should be https

// "http://superim-fe-v1.s3-website.il-central-1.amazonaws.com/"

// * BE-API
// export const urlBE = `https://${APIipBE}:${ports.https}/`;
export const urlBE =
  process.env.NODE_ENV === "development" ? `http://localhost:${ports.http}/api/v1/` : `https://api.tozilu.net:${ports.https}/api/v1/`;

// * FE-API
export const urlFE = `https://${APIipFE}:${ports.frontEnd}/`;
// export const urlFE = `http://${APIipFE}:${ports.frontEnd}/`;

// categories icons
export const iconSizeDefault = "2rem";
