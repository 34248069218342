import { CSSProperties, FC, useMemo, ReactNode } from "react";
import "./Typography.css"; // Import CSS file for typography styles
import AnimatedComponent from "../../AnimatedComponent/AnimatedComponent";

export interface TypographyProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span"; // Variant of the typography
  className?: string; // Additional CSS class names
  children: ReactNode; // Content of the typography component
  ellipsis?: boolean;
  width?: string | number;
  strong?: boolean;
  weight?: number;
  secondary?: boolean;
  color?: string;
  style?: CSSProperties;
  align?: "center" | "right" | "left";
  size?: number | string;
  useAnimationOnScroll?: string;
}

interface TextProps extends TypographyProps {
  ellipsis?: boolean;
  onClick?: () => void;
  strong?: boolean;
  type?: "secondary" | "success" | "warning" | "danger";
  underline?: boolean;
}

interface TitleProps extends TypographyProps {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

interface ParagraphProps extends TypographyProps {
  onClick?: () => void;
  type?: "secondary" | "success" | "warning" | "danger";
  underline?: boolean;
}

const Typography: FC<TypographyProps> & {
  Text: FC<TextProps>;
  Title: FC<TitleProps>;
  Paragraph: FC<ParagraphProps>;
} = ({
  useAnimationOnScroll,
  variant = "span",
  className = "",
  children,
  ellipsis,
  color,
  align,
  style,
  size,
  width,
  strong,
  weight,
  secondary,
  ...rest
}) => {
  const Tag = variant as keyof JSX.IntrinsicElements;

  const combinedStyles: CSSProperties = {
    textAlign: align,
    width: width,
    fontWeight: strong ? "bold" : weight,
    color,
    fontSize: size,
    ...style // This will override only the properties specified in style prop
  };

  const childrenToRender = useMemo(() => {
    if (useAnimationOnScroll) {
      return <AnimatedComponent animationClass={useAnimationOnScroll}>{children}</AnimatedComponent>;
    }
    return children;
  }, [children, useAnimationOnScroll]);

  return (
    <Tag
      style={combinedStyles}
      className={`typography-${variant} ${ellipsis ? " typography-ellipsis" : ""} ${secondary ? "typography-secondary" : ""} ${
        className ? className : "typography"
      }`}
      {...rest}
    >
      {childrenToRender}
    </Tag>
  );
};

Typography.Text = ({ children, type, ...rest }) => {
  return (
    <Typography variant="span" className={`typography-text typography-${type}`} {...rest}>
      {children}
    </Typography>
  );
};

Typography.Title = ({ level = 1, children, className = "typography", style, color, size, strong, ...rest }) => {
  return (
    <Typography className={`${className}  typography-h${level}`} {...rest} style={{ color, fontWeight: strong ? "bold" : 400, ...style }}>
      {children}
    </Typography>
  );
};

Typography.Paragraph = ({ children, type, ...rest }) => {
  return (
    <Typography className={`typography-paragraph typography-${type}`} {...rest} variant="p">
      {children}
    </Typography>
  );
};

export default Typography;
