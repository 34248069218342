import { FC, useState } from "react";
import { handleImageError } from "../../../utils/utils";
import "./ImageComponent.css";

type Props = {
  src?: string;
  alt: string;
  height?: string | number;
  width?: string | number;
  onClick?: () => void;
  cssClass?: string;
  id?: string;
  onErrorSize?: "small" | "medium";
  loading?: "lazy" | "eager";
  zoomSize?: "sm" | "md" | "lg";
};

const ImageComponent: FC<Props> = ({ src, alt, onClick, height, width, cssClass, id, onErrorSize, loading, zoomSize }) => {
  const [imageLoaded, setImageLoaded] = useState(true);
  return (
    <div id="image-component-wrapper">
      {imageLoaded && <div style={{ width, height }} className={`skeleton-rect ${cssClass}`}></div>}
      <img
        src={src || require("../../../assets/images/Image_not_available.png")}
        alt={alt}
        onError={e => handleImageError(e, onErrorSize)}
        onLoad={() => setImageLoaded(false)}
        height={height}
        width={width}
        onClick={() => onClick && onClick()}
        loading={loading}
        className={`${onClick ? "img-base img-active" : "img-base"} ${cssClass ?? ""} ${zoomSize ? `img-zoom img-zoom-${zoomSize}` : ""}`}
        id={id}
      />
    </div>
  );
};

export default ImageComponent;
