import { Dispatch, SetStateAction } from "react";
import { StoreEn } from "../../../../../../types/types";
import { fetchedProductsDataType } from "../../AdminMatchingProducts";
import { AdminSelectedItemType } from "../MatchingGrid";
import AdminItemCard from "./AdminItemCard";
import { Flex } from "../../../../../UI";

const AdminStoreItems = ({
  label,
  products,
  storeNameEn,
  favoriteName,
  setSelectedItem
}: {
  products: fetchedProductsDataType;
  label: string;
  storeNameEn: StoreEn;
  favoriteName: StoreEn;
  id: number;
  setSelectedItem: Dispatch<SetStateAction<AdminSelectedItemType>>;
}) => {
  const drawItems = () => {
    return Object.keys(products).map(k => {
      return (
        <AdminItemCard
          key={k}
          store={storeNameEn}
          favoriteName={favoriteName}
          product={products[k][storeNameEn]}
          refProduct={products[k][favoriteName]!}
          setSelectedItem={storeNameEn === favoriteName ? undefined : setSelectedItem}
        />
      );
    });
  };
  return (
    <Flex flexDirection="column" gap={6} style={{ border: "var(--border)", background: "var(--bg-primary-color)", padding: "2px" }}>
      <h3 className="product_matching-store_header">{label}</h3>
      {drawItems()}
    </Flex>
  );
};

export default AdminStoreItems;
