import { useCallback, useEffect, useState } from "react";
import { Card, Flex, SkeletonCard } from "../../components/UI";
import "./CartComparisonPage.css";
import serverAPI from "../../api/server";
import { SplitCart, StoreEn } from "../../types/types";
import { useNavigate } from "react-router-dom";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import OnlineStoresDict from "../../utils/onlineStoresDict";
import { useCartStore } from "../../Zustand";
import { CartComparisonLegend } from "./Helpers/Ui";
import CartComparisonPageMainContent from "./MainContent/CartComparisonPageMainContent";
import { TourProvider } from "../../components/Tour/TourContext";
import Tour from "../../components/Tour/Tour";
import { resolveImgUrl } from "../../utils/utils";

/** Parent Component use to Fetch only - same as simple route loader but it seems hard to implement it with zustand
 * - main goal here is fetch only
 * - The main logic inside the component is inside the children -"CartComparisonPageMainContent"*/
const CartComparisonPage = () => {
  const {
    user: { language },
    onlineStores: { favoriteStore, otherStores }
  } = useCustomContext();

  const { cart, setCart } = useCartStore();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const itemsKeys = Object.keys(cart);

  // Fill current cart with new fetch data from other stores
  const updateCart = useCallback(
    (selectedItems: string[], splitedCart: SplitCart) => {
      setCart(prev => {
        selectedItems.forEach(item => {
          otherStores.forEach((s: StoreEn) => {
            if (splitedCart[item]) {
              prev[item].product[s] = splitedCart[item][s];
              if (prev[item].product[s]) {
                prev[item].product[s].img_url = resolveImgUrl(s, splitedCart[item][s].img_url);
              }
            }
          });
        });

        return prev;
      });
    },
    [setCart, otherStores]
  );

  const fetchCartsFromOtherStores = useCallback(async () => {
    const selectedItemsFromFavorite = itemsKeys.filter(item => {
      const productStores = Object.keys(cart[item].product) as StoreEn[];
      return !productStores.some(store => otherStores.includes(store));
    });

    // Prevent API call when Cart is already hold data on other stores (include null)
    if (!selectedItemsFromFavorite.length) return null;

    setIsLoading(true);

    const response = await serverAPI("cart/compare-items", {
      favoriteStoreId: OnlineStoresDict.getOnlineStoreIdFromName(favoriteStore),
      otherStores: OnlineStoresDict.convertOnlineStoresToArrOfObj(otherStores),
      selectedItems: selectedItemsFromFavorite
    });

    if (!response.data) {
      setIsLoading(false);
      throw new Error("todo error handler here");
    }

    updateCart(selectedItemsFromFavorite, response.data);
    setIsLoading(false);
  }, [cart, itemsKeys, favoriteStore, otherStores, updateCart]);

  useEffect(() => {
    fetchCartsFromOtherStores();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (itemsKeys.length < 1) navigate(-1);
  }, [itemsKeys, navigate]);

  if (isLoading)
    return (
      <>
        <Card style={{ margin: "50px auto" }}>
          <Card style={{ width: "100%", margin: "20px auto" }}>
            <SkeletonCard width={"100%"} height="60px" animatedElements={[{ height: "100%", width: "100%" }]} />
          </Card>
          <Flex justify="space-between" gap={10}>
            {[favoriteStore, ...otherStores].map(s => {
              return <SkeletonCard key={s} width={"300px"} height="350px" animatedElements={[{ height: "100%", width: "100%" }]} />;
            })}
          </Flex>
          <Card dataTour="legend-icons" style={{ width: "100%", margin: "15px auto" }}>
            <CartComparisonLegend />
          </Card>
        </Card>
      </>
    );

  return (
    <TourProvider>
      <Tour language={language} />
      <Card
        dataTour="step1-comparison-container"
        style={{ background: "var(--bg-primary-color)", marginTop: 20 }}
        responsive={{ md: { marginTop: 1 }, sm: { maxHeight: "80vh", overflow: "auto" } }}
      >
        <CartComparisonPageMainContent
          cart={cart}
          favoriteStore={favoriteStore}
          otherStores={otherStores}
          itemsKeys={itemsKeys}
          language={language}
        />
      </Card>
    </TourProvider>
  );
};

export default CartComparisonPage;
