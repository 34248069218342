import { useMemo, useState } from "react";
import "./SearchProduct.css";
import { SelectSearchAsync } from "../../../UI";
import serverAPI from "../../../../api/server";
import { Language, ProductDataType, StoreEn } from "../../../../types/types";
import FilterCategorySearch from "./FilterCategorySearch/FilterCategorySearch";
import OnlineStoresDict from "../../../../utils/onlineStoresDict";
import ProductSearchItemCard from "../../../ProductSearchItemCard/ProductSearchItemCard";

const SearchProduct = ({
  store,
  language,
  onSelected
}: {
  store: StoreEn;
  language: Language;
  onSelected: (selected: ProductDataType) => void;
}) => {
  const [resetSearch, setResetSearch] = useState(false);
  const [category, setCategory] = useState<{ key: number; name: string } | null>(null);

  const dictionary = useMemo(() => {
    return {
      searchPlaceHolder: {
        hebrew: "חיפוש מוצר בכל הקטגוריות",
        english: "Search Product In All Categories",
        arabic: "البحث عن المنتج في جميع الفئات",
        russian: "Поиск товара во всех категориях"
      },
      searchPlaceHolderWithFilter: {
        hebrew: "חיפוש מוצר בקטגוריית -",
        english: "Search Product In ",
        arabic: "البحث عن المنتج في",
        russian: "Поиск товара в"
      }
    };
  }, []);

  const handleSelected = (o: ProductDataType) => {
    onSelected(o);
  };

  const getDebounceData = useMemo(() => {
    const body = {
      store,
      id: OnlineStoresDict.getOnlineStoreIdFromName(store),
      category: category?.key,
      sMethod: "regex" as any, //1 mean regex , 2 similarity
      search: [""]
    };

    return {
      body: body,
      searchKey: "search",
      getOptionsFromApi: async () => {
        const res: { data: ProductDataType[] } = await serverAPI("products/common/get", body);
        return res.data
          .sort((a, b) => a[store].category - b[store].category)
          .map((i: any) => {
            return { value: i[store].item_code, label: i[store].item_name, data: i };
          });
      }
    };
  }, [store, category?.key]);

  const handleOnBlur = () => {
    setResetSearch(true);
  };

  const handleFocus = (e: any) => {
    e.preventDefault();
    setResetSearch(false);
  };

  const filters = {
    component: <FilterCategorySearch setCategory={setCategory} category={category} language={language} />,
    handleClose: () => setCategory(null)
  };

  return (
    <div className="search_product_wrapper" tabIndex={0} onBlur={handleOnBlur} onFocus={handleFocus} style={{ position: "relative" }}>
      <SelectSearchAsync
        checkIcon={false}
        handleSelected={handleSelected}
        placeholder={category ? dictionary.searchPlaceHolderWithFilter[language] + category?.name : dictionary.searchPlaceHolder[language]}
        voice={language}
        getDebounceData={getDebounceData}
        resetProps={[resetSearch]}
        Label={option => <ProductSearchItemCard product={option.data[store]} language={language} store={store} />}
        filters={filters}
        notFoundText=""
      />
    </div>
  );
};

export default SearchProduct;
