import { FC, useMemo } from 'react';
import './ItemNotFoundDescription.css';
import { Language } from '../../../../../types/types';
import { Card, Flex, InfoBox, Typography } from '../../../../UI';

interface IProps {
  language: Language;
}

const ItemNotFoundDescription: FC<IProps> = ({ language }) => {
  const translations = useMemo(() => {
    return {
      english: {
        infoBoxText: "Unfortunately, we couldn't find a matching item automatically. Here are some options:",
        options: [
          {
            id: 'search',
            label: 'Use Search',
            text: 'Try finding a similar item manually using the search bar above.'
          },
          {
            id: 'ignore',
            label: 'Ignore This Item',
            text: 'Continue with the comparison, and we’ll use a normalized price for better accuracy.'
          },
          {
            id: 'delete',
            label: 'Remove Item',
            text: 'Remove this item from your cart if it’s no longer needed.'
          }
        ],
        note: {
          label: 'Note',
          text: "The item may be out of stock, or we may not have found a match. We're continuously working to improve our search capabilities."
        }
      },
      hebrew: {
        infoBoxText: 'לצערנו לא מצאנו התאמה לפריט זה באופן אוטומטי. הנה כמה אפשרויות:',
        options: [
          { id: 'search', label: 'חיפוש', text: 'נסו למצוא פריט דומה ידנית בשורת החיפוש למעלה.' },
          { id: 'ignore', label: 'התעלם מפריט זה', text: 'המשיכו עם ההשוואה ונשתמש במחיר מנורמל לשם השוואה.' },
          { id: 'delete', label: 'הסר פריט זה', text: 'הסירו פריט זה מהעגלה אם אינו נחוץ.' }
        ],
        note: { label: 'הערה', text: 'ייתכן שהפריט חסר במלאי או שלא מצאנו התאמה. אנחנו עובדים על שיפור היכולות שלנו.' }
      },
      arabic: {
        infoBoxText: 'للأسف، لم نجد عنصرًا مطابقًا تلقائيًا. إليك بعض الخيارات:',
        options: [
          { label: 'استخدم البحث', text: 'حاول العثور على عنصر مشابه يدويًا باستخدام شريط البحث أعلاه.' },
          { label: 'تجاهل هذا العنصر', text: 'استمر في المقارنة وسنستخدم سعرًا مضبوطًا لدقة أفضل.' },
          { label: 'إزالة العنصر', text: 'قم بإزالة هذا العنصر من سلتك إذا لم تعد بحاجة إليه.' }
        ],
        note: {
          label: 'ملاحظة:',
          text: 'قد يكون العنصر غير متوفر في المخزون، أو ربما لم نجد تطابقًا. نحن نعمل على تحسين قدراتنا باستمرار.'
        }
      },
      russian: {
        infoBoxText: 'К сожалению, мы не смогли автоматически найти соответствующий товар. Вот несколько вариантов:',
        options: [
          {
            label: 'Использовать поиск',
            text: 'Попробуйте найти похожий товар вручную с помощью строки поиска выше.'
          },
          {
            label: 'Игнорировать этот товар',
            text: 'Продолжайте сравнение, и мы будем использовать нормализованную цену для большей точности.'
          },
          { label: 'Удалить товар', text: 'Удалите этот товар из корзины, если он больше не нужен.' }
        ],
        note: {
          label: 'Примечание',
          text: 'товар может отсутствовать на складе, или мы не смогли найти совпадение. Мы постоянно работаем над улучшением наших возможностей.'
        }
      }
    };
  }, []);

  const drawInfoBox = (
    <InfoBox
      bgColor="var(--bg-secondary-color)"
      content={<Typography.Text style={{ fontWeight: '590' }}>{translations[language].infoBoxText}</Typography.Text>}
      language={language}
      type="gray"
    />
  );

  return (
    <Card cssClass="item_not_found_description-card-content">
      {drawInfoBox}
      <Flex flexDirection="column" gap={20}>
        <p>
          <strong>{translations[language].options[0].label}</strong> : {translations[language].options[0].text}
        </p>
        <p>
          <strong>{translations[language].options[1].label}</strong> : {translations[language].options[1].text}
        </p>
        <p>
          <strong>{translations[language].options[2].label}</strong> : {translations[language].options[2].text}
        </p>

        <div>
          <p>
            <span>
              <strong>
                <em>{translations[language].note.label}</em>
              </strong>
            </span>
            <span> : </span>
            <span>{translations[language].note.text}</span>
          </p>
        </div>
      </Flex>
    </Card>
  );
};

export default ItemNotFoundDescription;
