import { createPortal } from "react-dom";
import Button, { ButtonProps } from "../Button/Button";
import Flex from "../Flex/Flex";
import { resolveDirClass } from "../../../utils/utils";
import { WarningIcon } from "../Icons";
import Typography from "../Typography/Typography";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import "./Popconfirm.css";

interface IPopConfirmProps {
  offset?: number;
  placement?: "top" | "bottom" | "left" | "right";
  popCssClass?: string;
  btnProps?: {
    cssClass?: string;
    variant?: ButtonProps["variant"];
    size?: ButtonProps["size"];
    type?: ButtonProps["type"];
    style?: CSSProperties;
  };
  children: ButtonProps["children"];
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  title: string | ReactNode;
  description?: string | ReactNode;
  closable?: boolean;
  popStyle?: CSSProperties;
  disabled?: boolean;
}

const PopConfirm = ({
  offset = 10,
  btnProps = { variant: "danger", size: "medium" },
  placement = "top",
  popCssClass = "",
  onConfirm,
  onCancel,
  confirmText = "כן",
  cancelText = "לא",
  children,
  title,
  description,
  closable = true,
  popStyle,
  disabled,
}: IPopConfirmProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [popPosition, setPopPosition] = useState<{ top?: number; left?: number; right?: number; bottom?: number }>({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  });
  const {
    user: { language },
  } = useCustomContext();

  useEffect(() => {
    if (buttonRef.current) {
      const { top, left, right, bottom, width, height } = buttonRef.current.getBoundingClientRect();
      switch (placement) {
        case "left":
          setPopPosition({ bottom: top, right: window.innerWidth + offset - left });
          break;
        case "right":
          setPopPosition({ bottom: top, left: right + offset });
          break;
        case "bottom":
          setPopPosition({ top: bottom + offset, left: left + width / 2 });
          break;
        default:
          setPopPosition({ bottom: top + offset + height / 2, left: left + width / 2 });
          break;
      }
    }
  }, [isVisible, offset, placement]);

  const handleOnConfirm = () => {
    onConfirm && onConfirm();
    setIsVisible(false);
  };
  const handleOnCancel = () => {
    onCancel && onCancel();
    setIsVisible(false);
  };

  const onBlur = (event: React.FocusEvent<HTMLDivElement, Element>) => {
    if (!closable) return;
    const popConfirmElement = document.getElementById("pop-confirm-window");
    if (!event.currentTarget.contains(popConfirmElement) && !popConfirmElement?.contains(event.relatedTarget)) {
      setIsVisible(false);
    }
  };

  return (
    <div className={resolveDirClass(language)} tabIndex={0} onBlur={onBlur}>
      {isVisible &&
        createPortal(
          <Flex
            gap={15}
            flexDirection="column"
            justify="space-between"
            style={{ ...popPosition, ...popStyle }}
            id="pop-confirm-window"
            tabIndex={-1}
            className={`pop-confirm-${placement} ${isVisible ? "pop-confirm-content-show" : ""} ${popCssClass}`}
          >
            <Flex align="center" justify="center" gap={5}>
              <Typography.Title level={6}>{title}</Typography.Title>
              <WarningIcon size="18px" />
            </Flex>
            <div style={{ margin: "5px auto" }}>{description}</div>
            <Flex flexDirection="row-reverse">
              <Button width={"fit-content"} size="small" onClick={handleOnConfirm}>
                {confirmText}
              </Button>
              <Button width={"fit-content"} size="small" type="default" onClick={handleOnCancel}>
                {cancelText}
              </Button>
            </Flex>
          </Flex>,
          document.body
        )}

      <button
        disabled={disabled}
        className={`${btnProps.cssClass} button btn-${btnProps.variant} btn-${btnProps.type} ${btnProps.size}`}
        ref={buttonRef}
        onClick={() => setIsVisible((p) => !p)}
        style={btnProps.style}
      >
        {children}
      </button>
    </div>
  );
};

export default PopConfirm;
